import { Modal } from '@material-ui/core';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, {FC, useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router';
import { contractType } from '../../constant';
import { dateFormat, dateTimeFormat, getArrSize, isDateBetween } from '../../utils/Utils';
import { addMonthsContract, adjustInstallment, adjustPercent, calculateInstallment, calculateInstallmentForce, closeContact, getContract, openContact, paidInstallment, updateInstallmentForce } from './ContractApi';
import * as Yup from 'yup'
import { ContractLog, ContractLogRef } from './ContractLog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown, faArrowUp, faEquals, faTimes } from '@fortawesome/free-solid-svg-icons';
import { calInterrest } from '../../modules/interrest/interrest';
import commafy from 'commafy';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core';
import Select from 'react-select';
import { InstallmentApi } from './InstallmentApi';
import { KTSVG } from '../../../_metronic/helpers';
import { confirmAlert } from 'react-confirm-alert';

const inits: IContractDetails = {
  orders: 0,
  contractType: '0',
  name: '',
  customer: {
    identificationId: '',
    funder: 0,
    prefix: -1,
    firstname: '',
    lastname: '',
    address: '',
    phoneNumber: '',
    facebook: '',
    lineId: '',
    email: '',
  },
  startDate: moment().add(543, 'year'),
  agent: undefined,
  funder: undefined,
  guarantor: undefined,
  amount: 0.00,
  total: 0.00,
  months: 0,
  percent: 0,
  files: [],
  description: '',
  installment: [],
}

const ContractManage: FC = () => {
  const {id} = useParams<{id: string}>();
  const [data, setData] = useState<IContractDetails>();
  const [showConfirmInterrest, setShowConfirmInterrest] = useState(false);
  const [mode, setMode] = useState<'detail' | 'increse_amount' | 'decrease_amount' | 'adjust_percent' | 'force_update_installment'>('detail');
  const [adjust, setAdjust] = useState<number>();
  const [selectInstallment, setSelectInstallment] = useState<any>();
  const [newPercent, setNewPercent] = useState<number>();
  const [adjustTable, setAdjustTable] = useState<Array<Installment>>();
  const [closeContractModal, showCloseContractModal] = useState(false);
  const [openContractModal, showOpenContractModal] = useState(false);
  const [addMonthModal, showAddMonthModal] = useState(false);
  const [addMonth, setAddMonth] = useState<number>();
  const [editInterrest, setEditInterrest] = useState<number>();
  const contractLogRef = useRef<ContractLogRef>(null);
  const selectInstallmentRef = useRef<any>(null);
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({checked: [], paid: []}, {contract: data?data:inits}),
    onSubmit: (v) => {}
  })

  const formikConfirm = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      listChecking: Yup.array().of(Yup.object({
        paid: Yup.number(),
      }))
    }),
    initialValues: {
      listChecking: formik.values.contract?.installment.filter(ins => formik.values.checked.some(d => ins.id?.toString() === d)).map((d) => {
        return {
          id: d.id,
          paid: d.interrest - d.paid,
          installment: d,
        }
      })
    },
    onSubmit: (v) => {
      if (data && v.listChecking) {
        toast.promise(paidInstallment(data, v.listChecking?.map((d) => {
          return {
            id: d.id?d.id:0,
            paid: d.paid,
          }
        }), axios.CancelToken.source().token), {
          success: 'ชำระสำเร็จ',
          error: 'เกิดข้อผิดพลาด',
          pending: 'กำลังประมวลผล',
        }).then(() => {
          reload();
        })
      }
    }
  })

  useEffect(() => {
    if (data === undefined) {
      getContract(Number.parseInt(id), axios.CancelToken.source().token)
        .then(({data}) => {
          setData(data);
        })
        .catch()
        .finally()
    }
  }, [id, data])

  useEffect(() => {
    if (data && adjust) {
      calculateInstallment(data, adjust, axios.CancelToken.source().token)
        .then(({data}) => {
          setAdjustTable(data);
        })
        .catch()
        .finally()
    } else if (data && newPercent) {
      calculateInstallment(data, 0, axios.CancelToken.source().token, newPercent)
        .then(({data}) => {
          setAdjustTable(data);
        })
        .catch()
        .finally()
    }
  }, [adjust, newPercent, data])

  const reload = () => {
    getContract(Number.parseInt(id), axios.CancelToken.source().token)
        .then(({data}) => {
          setData(data);
          contractLogRef.current?.reload();
        })
        .catch()
        .finally()
  }

  const getSymbolStatus = (ins: Installment) => {
    if (ins.paid >= ins.interrest) {
      return 'bg-light-success text-success'
    } else if (moment(new Date()).isAfter(moment(ins.paymentDate)) && (ins.paid < ins.interrest)) {
      return 'bg-light-danger text-danger'
    } else {
      return 'bg-light text'
    }
  }

  const getLastestPaid = (ins: Array<Installment>): any => {
    if (ins) {
      const list = ins.filter((i) => i.paidDate?true:false);
      let i;
      if (list.length > 0) {
        i = list[list.length-1];
      } else {
        i = ins[0]
      }
      if (i) {
        return {
          value: i.id,
          label: `งวดที่ ${i.order} - ${dateFormat(i.paymentDate)}`
        }
      }
    }
    return undefined;
  }

  const getNewinterrestView = (ins?: Installment, oldIns?: Installment) => {
    if (ins && oldIns) {
      if (ins.interrest > oldIns.interrest) {
        return <span className="text-success text-nowrap"><FontAwesomeIcon icon={faArrowUp} />{commafy(ins.interrest.toFixed(2))}</span>
      } else if (ins.interrest < oldIns.interrest) {
        return <span className="text-danger text-nowrap"><FontAwesomeIcon icon={faArrowDown} />{commafy(ins.interrest.toFixed(2))}</span>
      } else {
        return <span className="text-muted text-nowrap"><FontAwesomeIcon icon={faEquals} />{commafy(ins.interrest.toFixed(2))}</span>
      }
    } else {
      return <></>
    }
  }

  const getDate = (str?: string) => {
    if (str) {
      return `(${dateFormat(str)})`
    }
    return ''
  }

  const applyNewInterrest = async (i: Installment, v: string) => {
    console.log(i.interrest !== Number.parseFloat(v.split(',').join('')));
    if (i.interrest !== Number.parseFloat(v.split(',').join(''))) {
      toast.promise(InstallmentApi.editInterrest(i, Number.parseFloat(v.split(',').join('')), axios.CancelToken.source().token), {
        pending: 'กำลังแก้ไขดอกเบี้ย',
        success: 'แก้ไขดอกเบี้ยสำเร็จ',
        error: 'เกิดข้อผิดพลาด'
      })
      .then(() => reload())
      .finally(() => {
        setEditInterrest(undefined);
      });
    } else {
      setEditInterrest(undefined);
    }
  }

  const amount = formik.values.contract?.amount;
  const percent = formik.values.contract?.percent;

  return <>
    <PageTitle breadcrumbs={[{
      title: 'ข้อมูลสัญญา',
      path: '/contract/list/open',
      isActive: false,
    }]}>{data?.contractTypeName +' - '+ data?.name}</PageTitle>
    <div className="row">
      
      <div className="col-sm-12 col-lg-4 gy-xs-4">
        <div className="card overlay overlay-block" style={{cursor: 'default'}}>
          {
            data?.status === 2 &&
            <div className="overlay-layer rounded bg-dark bg-opacity-10" style={{cursor: 'default'}}>
            </div>
          }
          <div className="card-header">
            <div className="card-title">ตารางผ่อน</div>
          </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="card-body p-0">
                  {formik.values.contract?.installment.map((d, i) => 
                    <div key={i} 
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title={`อัปเดทเมื่อ ${dateTimeFormat(d.updated)}`}
                      className={`d-flex align-items-center px-6 py-4 ${isDateBetween(moment(new Date()), moment(d.paymentDate).subtract(1, 'M'), moment(d.paymentDate))?'bg-light':''}`}>
                      <div className={`symbol symbol-45px`}>
                        <span className={`symbol-label ${getSymbolStatus(d)} fs-6 fw-bolder`}>
                          {d.order}
                        </span>
                      </div>
                      <div className='flex-grow-1 mx-5'>
                        <span className='text-gray-800 text-hover-primary fw-bolder fs-6 d-flex align-items-center'>
                          <span>{commafy(d.paid.toFixed(2))}</span>
                          <span>/</span>
                          { editInterrest === d.id ?
                            <>
                              <NumberFormat
                                defaultValue={d.interrest.toFixed(2)}
                                onBlur={(e) => {
                                  applyNewInterrest(d, e.currentTarget.value)
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    applyNewInterrest(d, e.currentTarget.value);
                                  }
                                }} 
                                thousandSeparator={','}
                                allowNegative={false} 
                                decimalScale={2}/>
                            </>
                            :
                            <>
                              <span style={{cursor: 'text'}} onClick={() => {
                                if (d.paid < d.interrest) {
                                  setEditInterrest(d.id)
                                }
                              }}>{commafy(d.interrest.toFixed(2))}</span>
                            </>
                          } {getNewinterrestView(adjustTable?.find(at => at.id === d.id), d)}
                        </span>
                        <span className='text-muted fw-bold d-block'>{
                          dateFormat(d.paymentDate)
                        }</span>
                      </div>
                      { d.paid < d.interrest ?
                        <div className={`form-check form-check-custom mx-5`}>
                          <input className={`form-check-input`} {...formik.getFieldProps('checked')} type="checkbox" value={d.id} />
                        </div>
                        :
                        <div className={`btn btn-sm btn-icon btn-color-danger btn-active-light-danger cursor-pointer`}
                          onClick={() => {
                            confirmAlert({
                              customUI: ({ onClose }) => {
                                return (
                                  <div className='card shadow-sm'>
                                      <div className="card-body">
                                        <h3 className="card-title">ยกเลิกการชำระดอกเบี้ยงวดที่ {d.order}</h3>
                                        <div className="d-flex justify-content-end card-toolbar mt-4">
                                          <button onClick={() => {
                                            toast.promise(InstallmentApi.unpaid(d, axios.CancelToken.source().token)
                                              .finally(() => reload()), {
                                              pending: 'กำลังยกเลิกการชำระดอกเบี้ย',
                                              success: 'ยกเลิกกำชำระดอกเบี้ยสำเร็จ',
                                              error: 'เกิดข้อผิดพลาด'
                                            }).finally(() => onClose());
                                          }} className="btn btn-active-light-danger">ตกลง</button>
                                          <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                        </div>
                                      </div>
                                  </div>
                                );
                              }
                            });
                          }}
                        >
                          <KTSVG path={'/media/icons/duotone/Text/Undo.svg'} className='text-danger svg-icon-2' />
                        </div>
                      }
                    </div>)
                  }
                  { addMonth &&
                      getArrSize(addMonth).map((k, i) => <div key={i} className={`my-1 d-flex align-items-center px-6 py-4 bg-light-success`}>
                      <div className={`symbol symbol-45px`}>
                        <span className={`symbol-label fs-6 fw-bolder bg-success text-white`}>
                          {(formik.values.contract?formik.values.contract.installment.length:0) + (k+1)}
                        </span>
                      </div>
                      <div className='flex-grow-1 mx-5'>
                        <span className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                          <span className="mr-4 text-success">{commafy(calInterrest(amount?amount:0, percent?percent:1).toFixed(2))}</span>
                        </span>
                        <span className='text-muted fw-bold d-block'>{
                          dateFormat(moment(data?.installment[data?.installment.length-1].paymentDate).add(k+1, 'M').toString())
                        }</span>
                      </div>
                    </div>)
                  }
              </div>
              <div className="card-footer">
                <div className="row">
                  <button onClick={() => setShowConfirmInterrest(true)} type="button" className="btn btn-primary">ชำระดอกเบี้ย</button>
                </div>
              </div>
          </form>     
        </div>
      </div>

      <div className="col-sm-12  col-lg-8">
        <div className="card overlay overlay-block" style={{cursor: 'default'}}>
          {
            data?.status === 2 &&
            <div className="overlay-layer rounded bg-dark bg-opacity-10" style={{cursor: 'default'}}>
            </div>
          }
          <div className="card-header ribbon">
            <div className="card-title">สัญญา - {contractType.find((ct) => (ct.id.toString() === data?.contractType.toString()))?.name}</div>
            { data?.status === 2 && <div className="ribbon-label bg-danger">ปิดบัญชี <FontAwesomeIcon className="mx-2 cursor-pointer" icon={faTimes} onClick={() => showOpenContractModal(true)}/></div>}
          </div>
          <div className="card-body">

            <div className="row">
              <div className="col-lg-12 mt-2">
                <label className="form-label">ยอดเงินกู้(บาท)</label>
                <span  className="form-control form-control-solid">{commafy(formik.values.contract?.amount)} <span className={`${adjust && adjust < 0?'text-danger':'text-success'}`}>{adjust && adjust >= 0&&'+'}{commafy(adjust)} {selectInstallment && getDate(data?.installment.find(i => i.id === selectInstallment.value)?.paymentDate)}</span> </span>
              </div>

              <div className="col-lg-6 mt-2">
                <label className="form-label">ยอดเงินกู้ทั้งหมด(บาท)</label>
                <span  className="form-control form-control-solid">{commafy(formik.values.contract?.total)}</span>
              </div>
              
              <div className="col-lg-3 mt-2">
                <label className="form-label">ดอกเบี้ย/เดือน</label>
                <span className={`form-control form-control-solid ${newPercent&&'text-warning'}`}>{newPercent ? <>
                  {newPercent}
                </>:<>
                  {formik.values.contract.percent}%
                </>}</span>
              </div>
              
              <div className="col-lg-3 mt-2">
                <label className="form-label">เดือน</label>
                <span  className="form-control form-control-solid">{commafy(formik.values.contract?.months)} <span className={`${addMonth && addMonth < 0?'text-danger':'text-success'}`}>{addMonth && addMonth >= 0&&'+'}{commafy(addMonth)}</span> </span>
              </div>

              <div className="col-lg-6 mt-2">
                <label className="form-label">วันที่เริ่มจ่าย</label>
                <span className="form-control form-control-solid">{dateFormat(formik.values.contract?.startDate)}</span>
              </div>
            </div>
          
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                {
                  data?.status === 1 && <>
                  <div>
                    <button className="btn btn-danger" onClick={() => showCloseContractModal(true)}>ปิดบัญชี</button>
                    <div className="btn btn-light-danger mx-2"
                      data-bs-toggle='tooltip'
                      title='บังคับคำนวณดอกเบี้ยใหม่'
                      onClick={async () => {
                      if (data) {
                        toast.loading({})
                        const res = await calculateInstallmentForce(data, axios.CancelToken.source().token)
                        setMode('force_update_installment')
                        setAdjustTable(res.data)
                        toast.dismiss()
                      }
                    }}><FontAwesomeIcon icon={faCalculator} /></div>
                  </div>
                  { !adjust && !addMonth && !newPercent && mode !== 'force_update_installment' ?
                    <div>
                      <button className="btn btn-primary mx-2" onClick={() => setMode('decrease_amount')} >ชำระเงินต้น</button>
                      <button className="btn btn-warning" onClick={() => setMode('adjust_percent')} >ปรับดอกเบี้ย</button>
                      <button className="btn btn-secondary mx-2" onClick={() => setMode('increse_amount')}>กู้เพิ่ม</button>
                      <button className="btn btn-secondary" onClick={() => showAddMonthModal(true)}>ต่อสัญญา</button>
                    </div>
                    :
                    <div>
                      <button className="btn btn-success mx-2" onClick={() => {
                        if (data && adjust) {
                          toast.promise(adjustInstallment(data, adjust, axios.CancelToken.source().token, {
                            installmentRef: selectInstallment?.value
                          }), {
                            success: 'ปรับยอดสำเร็จ',
                            error: 'เกิดข้อผิดพลาด',
                            pending: 'กำลังประมวลผล',
                          }).then(() => {
                            reload()
                            setAdjust(undefined);
                            setAdjustTable(undefined);
                            setSelectInstallment(undefined)
                          });
                        } else if (data && addMonth) {
                          toast.promise(addMonthsContract(data, addMonth, axios.CancelToken.source().token), {
                            success: 'ต่อสัญญาสำเร็จ',
                            error: 'เกิดข้อผิดพลาด',
                            pending: 'กำลังประมวลผล',
                          }).then(() => {
                            reload()
                            setAddMonth(undefined);
                          });
                        } else if (data && newPercent) {
                          toast.promise(adjustPercent(data, newPercent, axios.CancelToken.source().token), {
                            success: 'ปรับยอดสำเร็จ',
                            error: 'เกิดข้อผิดพลาด',
                            pending: 'กำลังประมวลผล',
                          }).then(() => {
                            reload()
                            setAdjustTable(undefined);
                            setNewPercent(undefined);
                          });
                        } else if (data && mode === 'force_update_installment') {
                          toast.promise(updateInstallmentForce(data, axios.CancelToken.source().token), {
                            success: 'คำนวณดอกเบี้ยใหม่สำเร็จ',
                            error: 'เกิดข้อผิดพลาด',
                            pending: 'กำลังประมวลผล',
                          }).then(() => {
                            reload()
                            setAdjustTable(undefined);
                            setMode('detail')
                          })
                        }
                      }} >บันทึก</button>
                      <button className="btn btn-danger" onClick={() => {
                        setAdjust(undefined);
                        setAdjustTable(undefined);
                        setAddMonth(undefined);
                        setNewPercent(undefined);
                        setSelectInstallment(undefined)
                        setMode('detail')
                      }}>ยกเลิก</button>
                    </div>
                  }
                </>}
              </div>
            </div>
          </div>
        </div>
        <ContractLog ref={contractLogRef} reload={() => reload()} className="my-2" contract={Number.parseInt(id)} />
      </div>
      
    </div>
    <Modal className="w-90" style={{overflowY: 'auto'}} open={mode === 'increse_amount' || mode === 'decrease_amount' || mode === 'adjust_percent'}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === 'decrease_amount'&&'ชำระเงินต้น'}
              {mode === 'increse_amount'&&'กู้เพิ่ม'}
              {mode === 'adjust_percent'&&'ปรับดอกเบี้ย'}
            </h5>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            const a = e.currentTarget.adjustAmount.value.split(',').join('');
            if (mode === 'adjust_percent' && Number.isFinite(Number.parseFloat(a))) {
              setNewPercent(Number.parseFloat(a));
            } else if (!Number.isInteger(Number.parseFloat(a))) {
              setAdjust(undefined);
            } else if (mode === 'increse_amount') {
              setAdjust(Number.parseFloat(a));
            } else if (mode === 'decrease_amount' && a <= amount) {
              setSelectInstallment(selectInstallmentRef.current.getValue()[0])
              setAdjust(Number.parseFloat(a) * -1);
            }
            setMode('detail');
          }}>
            <div className="modal-body">
              {
                mode === 'decrease_amount' &&
                <Select  
                  ref={selectInstallmentRef}
                  placeholder="งวดที่..."
                  options={data?data.installment.map((l) => ({value: l.id, label: `งวดที่ ${l.order} - ${dateFormat(l.paymentDate)}`})):[]}
                  defaultValue={getLastestPaid(data?data.installment:[])}
                />
              }
              <div className="d-flex align-items-end my-2">
                <NumberFormat className="form-control flex-grow-1 fs-4"
                  name="adjustAmount"
                  thousandSeparator={true}
                  allowNegative={false}
                  placeholder={mode !== 'adjust_percent'?'บาท':'%'}
                />     
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" type="submit">
                ตกลง
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => setMode('detail')}>
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
    <Modal open={closeContractModal}>
      <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ยืนยันการปิดบัญชี</h5>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => {if (data) {
                toast.promise(closeContact(data, axios.CancelToken.source().token), {
                  success: 'ปิดสัญญาเรียบร้อย',
                  error: 'เกิดข้อผิดพลาด',
                  pending: 'กำลังประมวลผล',
                }).then(() => {
                  reload()
                  showCloseContractModal(false)
                })
              }}}>
                ตกลง
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => showCloseContractModal(false)}>
                ยกเลิก
              </button>
            </div>
          </div>
      </div>
    </Modal>
    <Modal open={openContractModal}>
      <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ยกเลิกการปิดบัญชี</h5>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => {if (data) {
                toast.promise(openContact(data, axios.CancelToken.source().token), {
                  success: 'ยกเลิกการปิดสัญญาเรียบร้อย',
                  error: 'เกิดข้อผิดพลาด',
                  pending: 'กำลังประมวลผล',
                }).then(() => {
                  reload()
                  showOpenContractModal(false)
                })
              }}}>
                ตกลง
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => showOpenContractModal(false)}>
                ยกเลิก
              </button>
            </div>
          </div>
      </div>
    </Modal>
    <Modal open={addMonthModal}>
      <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ต่อระยะเวลาสัญญา</h5>
            </div>
            <form onSubmit={(e) => {
              e.preventDefault();
              setAddMonth(Number.parseInt(e.currentTarget.months.value))
              showAddMonthModal(false);
            }}>
              <div className="modal-body">
                <input className="form-control flex-grow-1 fs-4"
                  type="number" 
                  name="months"
                  placeholder="เดือน"/>  
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" type="submit">
                  ตกลง
                </button>
                <button className="btn btn-secondary" type="button" onClick={() => showAddMonthModal(false)}>
                  ยกเลิก
                </button>
              </div>
            </form>
          </div>
      </div>
    </Modal>
    <Modal className="w-90" style={{overflowY: 'auto'}} open={showConfirmInterrest}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">ยืนยันการชำระดอกเบี้ย</h5>
          </div>
          <div className="modal-body">
            <form onSubmit={formikConfirm.handleSubmit}>
              {formikConfirm.values.listChecking?.map((d, i) => 
              <div key={i} className={`d-flex align-items-center py-4`}>
                <div className={`symbol symbol-45px`}>
                  <span className={`symbol-label ${getSymbolStatus(d.installment)} fs-6 fw-bolder`}>
                    {d.installment.order}
                  </span>
                </div>
                <div className='flex-grow-1 d-flex justify-content-between'>
                  <span className='text mx-2 fw-bold d-block'>{
                    dateFormat(d.installment.paymentDate)
                  }</span>
                  <span className='text-gray-800 text-hover-primary fw-bolder'>
                    <input className="fs-4" style={{
                      border: 'none', textAlign: "right",
                      borderBottom: '1px solid #ccc',
                    }}
                    value={commafy(formikConfirm.values.listChecking?formikConfirm.values.listChecking[i].paid:0)}
                    onChange={(e) => {
                      const paid = Number.parseInt(e.target.value.split(',').join(''));
                      if (paid > 0 && paid <= d.installment.interrest) {
                        formikConfirm.setFieldValue(`listChecking[${i}].paid`, paid);
                      } else if (paid > d.installment.interrest) {
                        formikConfirm.setFieldValue(`listChecking[${i}].paid`, d.installment.interrest);
                      } else {
                        formikConfirm.setFieldValue(`listChecking[${i}].paid`, 0)
                      }
                    }} />/<span className="fs-4">{commafy(d.installment.interrest - d.installment.paid)}</span>
                  </span>
                </div>
              </div>)}
            </form>
            <div className="separator border-dark border-1 my-5"></div>
            <div className="row">
              <div className="d-flex justify-content-between">
                <span className="fw-bold fs-1 align-self-end">ทั้งหมด</span>
                <span className="fw-bold fs-1 align-self-end">{commafy(formikConfirm.values.listChecking?.reduce((acc, ins) => {
                  acc += ins.paid;
                  // acc += Number.parseFloat((ins.paid).toString().split(',').join(''));
                  return acc;
                }, 0))} บาท</span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" onClick={() => setShowConfirmInterrest(false)}>
              ยกเลิก
            </button>
            <button type="button" className="btn btn-primary" onClick={() => {
              formikConfirm.submitForm();
              setShowConfirmInterrest(false);
            }}>ตกลง</button>
          </div>
        </div>
      </div>
    </Modal>
  </>
}

export {ContractManage}
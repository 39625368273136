import axios from 'axios';
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { SearchUserModal } from '../modules/select-user-modal/SelectUserModal';
import { getUserList } from '../pages/user/UserApi';
import { getProfilePhoto } from '../utils/Utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  userId: number,
  disabled?: boolean,
  onChangeUser?(u: IUserDetails): void,
}

export interface DisplayUserSelectedRef {
  getFunder(): IUserDetails | undefined
}

const DisplayUserSelected = forwardRef<DisplayUserSelectedRef, Props>((props: Props, ref) => {
  const [funder, setFunder] = useState<IUserDetails>();
  const [closeFunderList, setCloseFunderList] = useState(true)
  useImperativeHandle(ref, () => ({
    getFunder: () => {
      return funder;
    }
  }))
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    getUserList({
      filter: {id: props.userId},
      limit: 1,
    }, cancelTokenSource.token)
      .then(({data}) => {
        setFunder(data.list[0])
      })
      .catch(() => {})
    return () => cancelTokenSource.cancel()
  }, [props.userId])
  return <>
    <div className={props.className}>
      <label className="form-label">นายทุน</label>
      <div
        className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
        { funder ?
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px border'>
              <img alt='Pic' src={getProfilePhoto(funder.profilePhoto)} />
            </div>

            <div className='ms-5'>
              <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                {funder.username}
              </span>
              <div className='fw-bold text-dark'>{funder.fullname}</div>
            </div>
          </div> : <div>
            <div className='symbol symbol-45px'>
              <img alt='Pic' src={getProfilePhoto(null)} />
            </div>
            <span className="m-2">กรุณาเลือกนายทุน</span>
          </div>
        }
        {
          props.disabled ? <>
          </> : <>
            <div className="overlay-layer bg-dark bg-opacity-25">
              <span onClick={() => setCloseFunderList(false)} className="btn btn-primary btn-shadow">เลือก</span>
              <span onClick={() => setFunder(undefined)} className="btn btn-light-danger btn-shadow ms-2">ลบ</span>
            </div>
          </>
        }
      </div>
    </div>
    <SearchUserModal show={!closeFunderList} 
      filter={{isFunder: true}}
      handleClose={() => {setCloseFunderList(true)}}
      handleSubmit={(u) => {
        setCloseFunderList(true)
        setFunder(u);
        if (props.onChangeUser) props.onChangeUser(u);
      }} ></SearchUserModal>
  </>
});

export {DisplayUserSelected}
import React, {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper2'
import { UserCreate } from '../pages/user/UserCreate'
import { UserUpdate } from '../pages/user/UserUpdate'
import { UserListPage } from '../pages/user/UserListPage'
import { CustomerListPage } from '../pages/customer/CustomerListPage'
import { CreateContractPage } from '../pages/contract/CreateContractPage'
import { CustomerCreateForm } from '../pages/customer/CustomerCreateForm'
import TableContract from '../pages/contract/TableContract'
import { CustomerUpdate } from '../pages/customer/CustomerUpdate'
import { ContractDetail } from '../pages/contract/ContractDetail'
import { ContractManage } from '../pages/contract/ContractManage'
import { PrefixListPage } from '../pages/prefix/PrefixListPage'
import { ReportWrapper } from '../pages/report/ReportWrapper'
import { DocumentPdf } from '../pages/document/document-pdf'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/report/income' component={ReportWrapper} />
        <Route path='/user/list' component={UserListPage} />
        <Route path='/user/create' component={UserCreate} />
        <Route path='/user/update/:id' component={UserUpdate} />
        <Route path='/prefix/list' component={PrefixListPage} />
        <Route path='/customer/list' component={CustomerListPage} />
        <Route path='/customer/create' component={CustomerCreateForm} />
        <Route path='/customer/update/:id' component={CustomerUpdate} />
        <Route path='/contract/create' component={CreateContractPage} />
        <Route path='/contract/list/:def' component={TableContract} />
        <Route path='/contract/:id/manage' component={ContractManage} />
        <Route path='/contract/:id' component={ContractDetail} />
        <Route path='/document' component={DocumentPdf} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}

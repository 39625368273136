export const contractType = [
  {id: 1, name: 'จำนอง', description: ''},
  {id: 2, name: 'จำนำ', description: ''},
  {id: 3, name: 'จำนำ (ปลอด)', description: ''},
  {id: 4, name: 'ขายฝาก', description: ''},
  {id: 5, name: 'ซื้อมาขายกลับ', description: ''},
  {id: 6, name: 'เงินกู้', description: ''},
]

export const prefix = [
  {id: -1, name: 'กรุณาเลือกคำนำหน้า..', description: ''},
  {id: 1, name: 'นาย', description: ''},
  {id: 2, name: 'นาง', description: ''},
  {id: 3, name: 'นางสาว', description: ''},
  {id: 0, name: 'อื่นๆ', description: ''}
]
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getUser, updateUser} from './UserApi'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actions, IAuthState } from '../../modules/auth'
import { getProfilePhoto } from '../../utils/Utils'
import InpurMark from 'react-input-mask'
import { toast } from 'react-toastify'
import { RootState } from '../../../setup'

const profileDetailsSchema = Yup.object().shape({
  prefix: Yup.string().test('', 'กรุณากรอกเลือกคำนำหน้า', (v) => v !== '-1'),
  firstname: Yup.string().required('กรุณากรอกชื่อ'),
  lastname: Yup.string().required('กรุณากรอกรหัสผ่าน'),
  address: Yup.string(),
  phoneNumber: Yup.string(),
  lineId: Yup.string(),
  email: Yup.string().email('email ไม่ถูกต้อง'),
})

const initialValues: IUserDetails = {
  username: '',
  password: '',
  passwordConfirmation: '',
  prefix: -1,
  firstname: '',
  lastname: '',
  address: '',
  phoneNumber: '',
  lineId: '',
  email: '',
  isAdmin: false,
  types: {
    isFunder: false,
    isAgent: false,
  },
  profilePhoto: null,
}

const UserUpdateForm: React.FC = () => {
  let {id} = useParams<any>();
  const dispatch = useDispatch()
  const auth: IAuthState = useSelector((selector: any) => selector.auth);
  const prefix = useSelector((selector: RootState) => selector.prefix)
  const [user, setUser] = useState<IUserDetails>()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IUserDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setLoading(true)
      toast.promise(updateUser(values), {
        success: "บันทึกสำเร็จ",
        error: "เกิดข้อผิดพลาด",
        pending: "กำลังบันทึก",
      }).then((res) => {
          formik.setValues(res.data)
          if (user?.id === auth.user?.id) {
            dispatch(actions.fulfillUser(res.data))
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          setLoading(false)
        })
    },
  })

  if (!auth.user?.isAdmin && auth.user?.id?.toString() !== id) return <>
    <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
      <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
        <KTSVG path={'/media/icons/duotone/Code/Info-circle.svg'} />
      </span>
      
      <div className="d-flex flex-column">
        <h5 className="mb-1">ระดับการใช้งาน</h5>
        <span>ระดับการใช้งานของคุณไม่เพียงพอ.</span>
      </div>
    </div>
  </>

  if (!user) {
    getUser(id)
      .then((res) => {
        setUser(res.data);
        formik.setValues(res.data);
      })
      .catch()
    return <>loading ...</>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        role='button'
        aria-expanded='true'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>ข้อมูลผู้ใช้งานระบบ</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>รูปโปรไฟล์</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}>
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${getProfilePhoto(formik.getFieldProps('profilePhoto').value)})`}}
                  ></div>
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                      </svg>
                      <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
                        formik.setFieldValue('profilePhoto', e.target.files?.item(0))
                      }}/>
                  </label>
                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Remove avatar" onClick={() => formik.setFieldValue('profilePhoto', null)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x fs-2" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>คำนำหน้าชื่อ</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-lg'
                  {...formik.getFieldProps('prefix')}
                >
                  <option value='-1'>กรุณาเลือกคำนำหน้า..</option>
                  { prefix.map((p, i) => <option key={i} value={p.id}>{p.name}</option>) }
                </select>
                {formik.touched.prefix && formik.errors.prefix && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.prefix}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ชื่อ - นามสกุล</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg mb-3 mb-lg-0'
                      placeholder='ชื่อ'
                      {...formik.getFieldProps('firstname')}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstname}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='นามสกุล'
                      {...formik.getFieldProps('lastname')}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ที่อยู่</label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg'
                  placeholder='ที่อยู่'
                  {...formik.getFieldProps('address')}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>เบอร์โทร</label>

              <div className='col-lg-8 fv-row'>
                <InpurMark
                  mask="999-999-9999"
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='เบอร์โทร'
                  {...formik.getFieldProps('phoneNumber')}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Line ID</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Line ID'
                  {...formik.getFieldProps('lineId')}
                />
                {formik.touched.lineId && formik.errors.lineId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lineId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>E-mail</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            { auth.user?.isAdmin && <>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ตำแหน่ง</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline me-5'>
                    <input
                      className='form-check-input'
                      name='types[]'
                      type='checkbox'
                      checked={formik.getFieldProps('types.isFunder').value}
                      onChange={(e) => {
                        formik.setFieldValue('types.isFunder', e.target.checked)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>นายทุน</span>
                  </label>

                  <label className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      name='types[]'
                      type='checkbox'
                      checked={formik.getFieldProps('types.isAgent').value}
                      onChange={(e) => {
                        formik.setFieldValue('types.isAgent', e.target.checked)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>นายหน้า</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ระดับการใช้งาน</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline me-5'>
                    <input
                      className='form-check-input'
                      name='role'
                      type='checkbox'
                      checked={!formik.getFieldProps('isAdmin').value}
                      onChange={() => {
                        formik.setFieldValue('isAdmin', false)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>User</span>
                  </label>

                  <label className='form-check form-check-inline'>
                  <input
                      className='form-check-input'
                      name='role'
                      type='checkbox'
                      checked={formik.getFieldProps('isAdmin').value}
                      onChange={() => {
                        formik.setFieldValue('isAdmin', true)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>Admin</span>
                  </label>
                </div>
              </div>
            </div>
            </>
            }
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'บันทึก'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  กำลังบันทึก...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {UserUpdateForm}

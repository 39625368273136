import axios, { CancelToken } from 'axios'
import { Moment } from 'moment'
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
export const CREATE_CONTRACT = `${API_URL}/v1/contract/create`
export const GET_CONTRACT_LIST = `${API_URL}/v1/contract/list`
export const GET_CONTRACT_BY_ID = `${API_URL}/v1/contract`
export const PAID_INSTALLMENT = `${API_URL}/v1/contract`
export const CALCULATE_INSTALLMENT = `${API_URL}/v1/contract`
export const API_CONTRACT = `${API_URL}/v1/contract`

export function createContract(contract: IContractDetails, cancel: CancelToken) {
  const formData = new FormData();
  const req = Object.assign({}, contract, {
    created: (contract.created as Moment).clone().utc(),
    startDate: (contract.startDate as Moment).clone().utc(),
  })
  formData.append('contract', JSON.stringify(req))
  contract.files.forEach((f: {name: string, file: File}) => {
    if (f.file instanceof File) {
      formData.append("documents", f.file)
    };
  })
  return axios({
    method: 'POST',
    url: CREATE_CONTRACT,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function getContractList(options: PaginationOption, cancel: CancelToken) {
  const param = new URLSearchParams()
  if (options.limit !== undefined) param.append('limit', options.limit.toString())
  if (options.offset !== undefined) param.append('offset', options.offset.toString())
  if (options.search && options.search !== '') param.append('search', options.search)
  if (options.filter?.from) param.append('from', options.filter.from)
  if (options.filter?.to) param.append('to', options.filter.to)
  if (options.filter?.paidStatus) param.append('paid_status', options.filter.paidStatus.join(','))
  if (options.filter?.status) param.append('status', options.filter.status.join(','))
  if (options.filter?.type) param.append('type', options.filter.type.join(','))
  if (options.filter?.funder) param.append('funder', options.filter.funder.join(','))
  if (options.sort?.id) param.append('id', options.sort.id)
  if (options.sort?.orders) param.append('orders', options.sort.orders)
  return axios.get(GET_CONTRACT_LIST+`?${param.toString()}`, {
    cancelToken: cancel,
  });
}

export function getContract(id: number, cancel: CancelToken) {
  return axios.get(`${GET_CONTRACT_BY_ID}/${id}`, {
    cancelToken: cancel,
  });
}

export function updateOrders(contract: IContractDetails, orders: number, cancel: CancelToken) {
  return axios.put(`${API_CONTRACT}/${contract.id}/orders`, {
    contract,
    orders,
  }, {
    cancelToken: cancel,
  });
}

export function paidInstallment(contract: IContractDetails, list: Array<{id: number, paid: number}>, cancel: CancelToken) {
  return axios.put(`${PAID_INSTALLMENT}/${contract.id}/paid`, {
    contract,
    list,
  }, {
    cancelToken: cancel,
  });
}

export function calculateInstallment(contract: IContractDetails, adjust: number, cancel: CancelToken, newPercent?: number) {
  const param = new URLSearchParams({
    adjust: adjust.toString(),
  });
  if (newPercent) param.append('percent', newPercent.toString())
  return axios.get(`${CALCULATE_INSTALLMENT}/${contract.id}/calculate/installment?${param.toString()}`, {
    cancelToken: cancel,
  });
}

export function calculateInstallmentForce(contract: IContractDetails, cancel: CancelToken) {
  return axios.get(`${CALCULATE_INSTALLMENT}/${contract.id}/calculate/installment/force`, {
    cancelToken: cancel,
  });
}

export function updateInstallmentForce(contract: IContractDetails, cancel: CancelToken) {
  return axios.put(`${CALCULATE_INSTALLMENT}/${contract.id}/calculate/installment/force`, {
    cancelToken: cancel,
  });
}

export function getLogs(id: number, cancel: CancelToken) {
  return axios.get(`${API_CONTRACT}/${id}/logs`, {
    cancelToken: cancel,
  });
}

export function adjustInstallment(contract: IContractDetails, adjust: number, cancel: CancelToken, opts?: any) {
  let param = {
    adjust: adjust,
  }
  param = {...param, ...opts}
  return axios.put(`${CALCULATE_INSTALLMENT}/${contract.id}/adjust/installment`, param, {
    cancelToken: cancel,
  });
}

export function adjustPercent(contract: IContractDetails, newPercent: number, cancel: CancelToken) {
  return axios.put(`${CALCULATE_INSTALLMENT}/${contract.id}/adjust/percent`, {
    percent: newPercent,
  }, {
    cancelToken: cancel,
  });
}

export function addMonthsContract(contract: IContractDetails, months: number, cancel: CancelToken) {
  return axios.put(`${CALCULATE_INSTALLMENT}/${contract.id}/add/months`, {
    months: months,
  }, {
    cancelToken: cancel,
  });
}

export function openContact(contract: IContractDetails, cancel: CancelToken) {
  return axios.put(`${API_CONTRACT}/${contract.id}/open`, {}, {
    cancelToken: cancel,
  });
}

export function closeContact(contract: IContractDetails, cancel: CancelToken) {
  return axios.put(`${API_CONTRACT}/${contract.id}/close`, {}, {
    cancelToken: cancel,
  });
}

export function deleteContract(contract: IContractDetails, cancel: CancelToken) {
  return axios.delete(`${API_CONTRACT}/${contract.id}`, {
    cancelToken: cancel,
  });
}

export function deletePaymentHistory(contract: number, paymentId: number, cancel: CancelToken) {
  return axios.delete(`${API_CONTRACT}/${contract}/payment/${paymentId}`, {
    cancelToken: cancel,
  });
}

export function updateContract(contract: IContractDetails, cancel: CancelToken) {
  const formData = new FormData();
  formData.append('contract', JSON.stringify(contract))
  contract.files.forEach((f: {name: string, file: File}) => {
    if (f.file instanceof File) {
      formData.append("newDocuments", f.file)
    };
  })
  return axios({
    method: 'PUT',
    url: `${API_CONTRACT}`, 
    data: formData,
    cancelToken: cancel,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import {PageTitle} from '../../../_metronic/layout/core'
import {TableUser} from './TableUser'

const UserListPage: FC = () => {
  const intl = useIntl()
  const auth = useSelector((s: RootState) => s.auth)
  return <>
    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USERS_DATA'})}</PageTitle>
    {
      auth.user?.isAdmin ? <>
        {/* begin::Row */}
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <TableUser className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
        {/* end::Row */}
      </>:<>
        ระดับการใช้งานไม่เพียงพอ
      </>
    }
  </>
}

export {UserListPage}

import axios from 'axios';
import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import { useParams } from 'react-router';
import { contractType } from '../../constant';
import { dateFormat, dateTimeFormat, getProfilePhoto, getUrlDoc } from '../../utils/Utils';
import { getContract, updateContract } from './ContractApi';
import commafy from 'commafy';
import { KTSVG } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { SearchUserModal } from '../../modules/select-user-modal/SelectUserModal';
import { useFormik } from 'formik';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core';
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap-v5';
import { Document, Page } from 'react-pdf';

const inits: IContractDetails = {
  orders: 0,
  contractType: '0',
  name: '',
  customer: {
    identificationId: '',
    funder: 0,
    prefix: -1,
    firstname: '',
    lastname: '',
    address: '',
    phoneNumber: '',
    facebook: '',
    lineId: '',
    email: '',
  },
  startDate: moment().add(543, 'year'),
  agent: undefined,
  funder: undefined,
  guarantor: undefined,
  amount: 0.00,
  total: 0.00,
  months: 0,
  percent: 0,
  files: [],
  description: '',
  installment: [],
}

const ContractDetail: FC = () => {
  const [width] = useState(window.innerWidth)
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     console.log(window.innerWidth)
  //     setWidth(window.innerWidth);
  //   }
  //   window.addEventListener('resize', updateSize);
  //   return () => window.removeEventListener('resize', updateSize);
  // })
  const {id} = useParams<{id: string}>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: inits,
    onSubmit: (v) => {
        toast.promise(
          async () => {
            await updateContract(v, axios.CancelToken.source().token).then(() => refresh)
            await refresh()
          },
          {
            success: 'แก้ไขเรียบร้อย',
            error: 'เกิดข้อผิดพลาด',
            pending: 'กำลังแก้ไข',
          })
    },
  });
  const [closeFunderList, setCloseFunderList] = useState(true)
  const [closeAgentList, setCloseAgentList] = useState(true)
  const [edit, setEdit] = useState(-1)
  const [urlDoc, setUrlDoc] = useState<{
    file: any,
    name: string,
    url: string,
  } | undefined >(undefined)
  const [numPdfPages, setNumPdfPages] = useState<number>()
  const [failLoadPDF, setFailLoadPDF] = useState<boolean>(false)
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    formik.setFieldValue('files', formik.getFieldProps('files').value.concat(acceptedFiles.map((f: any) => ({
      name: f.name,
      file: f,
    }))))
  }, [formik])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const refresh = () => {
    getContract(Number.parseInt(id), axios.CancelToken.source().token)
        .then(({data}) => {
          formik.setValues(data);
        })
        .catch()
        .finally()
  }

  useEffect(() => {
    if (formik.values.id === undefined) {
      getContract(Number.parseInt(id), axios.CancelToken.source().token)
        .then(({data}) => {
          formik.setValues(data);
        })
        .catch()
        .finally()
    }
  }, [formik, id])

  if (formik.values.funder?.id === formik.values.agent?.id) {
    formik.values.agent = undefined
  }
  
  return <>
    <PageTitle breadcrumbs={[{
      title: 'ข้อมูลสัญญา',
      path: '/contract/list/open',
      isActive: false,
    }]}>แก้ไขข้อมูลสัญญา</PageTitle>
    <div className="card">
      <div className="card-header">
        <div className="card-title">สัญญา - {contractType.find((ct) => (ct.id.toString() === formik.values?.contractType.toString()))?.name}</div>
      </div>
      <div className="card-body">
        <div className="w-100">
          <div className="row">
            <div className="col-ls-12">
              <h3>ข้อมูลผู้ทำสัญญา <Link to={`/customer/update/${formik.values?.customer?.id}`}><KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3'/></Link></h3>
              <div className="row">
                <span><strong className='col-lg-12'>เลขบัตรประชาชน: </strong> {formik.values?.customer?.identificationId}</span>
                <span><strong className='col-lg-12'>ชื่อ - นามสกุล: </strong> {`${formik.values?.customer?.firstname} ${formik.values?.customer?.lastname}`}</span>
                <span><strong className='col-lg-12'>เบอร์โทรศัพท์: </strong> {formik.values?.customer?.phoneNumber??'-'}</span>
                <span><strong className='col-lg-12'>อีเมล์: </strong> {formik.values?.customer?.email??'-'}</span>
                <span><strong className='col-lg-12'>Facebook: </strong> {formik.values?.customer?.facebook??'-'}</span>
                <span><strong className='col-lg-12'>Line ID: </strong> {formik.values?.customer?.lineId??'-'}</span>
                <span><strong className='col-lg-12'>ที่อยู่: </strong> {formik.values?.customer?.address??'-'}</span>
              </div>
            </div>
            <div className="separator border-2 my-5"></div>
            <div className="col-ls-12">
              <h3>ข้อมูลผู้ค้ำ{formik.values?.guarantor?'':' - ไม่มี'}
                {formik.values?.guarantor && <Link to={`/customer/update/${formik.values?.guarantor?.id}`}><KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3'/></Link>}
              </h3>
              { formik.values?.guarantor &&
                <div className="row">
                  <span><strong className='col-lg-12'>เลขบัตรประชาชน: </strong> {formik.values?.guarantor?.identificationId}</span>
                  <span><strong className='col-lg-12'>ชื่อ - นามสกุล: </strong> {`${formik.values?.guarantor?.firstname} ${formik.values?.guarantor?.lastname}`}</span>
                  <span><strong className='col-lg-12'>เบอร์โทรศัพท์: </strong> {formik.values?.guarantor?.phoneNumber??'-'}</span>
                  <span><strong className='col-lg-12'>อีเมล์: </strong> {formik.values?.guarantor?.email??'-'}</span>
                  <span><strong className='col-lg-12'>Facebook: </strong> {formik.values?.guarantor?.facebook??'-'}</span>
                  <span><strong className='col-lg-12'>Line ID: </strong> {formik.values?.guarantor?.lineId??'-'}</span>
                  <span><strong className='col-lg-12'>ที่อยู่: </strong> {formik.values?.guarantor?.address??'-'}</span>
                </div>
              }
            </div>
            <div className="separator border-2 my-5"></div>
            <div className="col-ls-12">
              <h3>รายละเอียดสัญญา</h3>
              <div className="row">

                <div className="col-lg-6 mt-2">
                  <label className="form-label">นายทุน</label>
                  <div
                    className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
                    { formik.values.funder ?
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px border'>
                          <img alt='Pic' src={getProfilePhoto(formik.values.funder.profilePhoto)} />
                        </div>

                        <div className='ms-5'>
                          <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                            {formik.values.funder.username}
                          </span>
                          <div className='fw-bold text-dark'>{formik.values.funder.fullname}</div>
                        </div>
                      </div> : <div>
                        <div className='symbol symbol-45px'>
                          <img alt='Pic' src={getProfilePhoto(null)} />
                        </div>
                        <span className="m-2">กรุณาเลือกนายทุน</span>
                      </div>
                    }
                    <div className="overlay-layer bg-dark bg-opacity-25">
                      <span onClick={() => setCloseFunderList(false)} className="btn btn-primary btn-shadow">เปลี่ยน</span>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6 mt-2">
                  <label className="form-label">นายหน้า</label>
                  <div
                    className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
                    { formik.values.agent ?
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px border'>
                          <img alt='Pic' src={getProfilePhoto(formik.values.agent.profilePhoto)} />
                        </div>

                        <div className='ms-5'>
                          <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                            {formik.values.agent.username}
                          </span>
                          <div className='fw-bold text-dark'>{formik.values.agent.fullname}</div>
                        </div>
                      </div> : <div>
                        <div className='symbol symbol-45px'>
                          <img alt='Pic' src={getProfilePhoto(null)} />
                        </div>
                        <span className="m-2">กรุณาเลือกนายหน้า</span>
                      </div>
                    }
                   <div className="overlay-layer bg-dark bg-opacity-25">
                      <span onClick={() => setCloseAgentList(false)} className="btn btn-primary btn-shadow">เลือก</span>
                      <span onClick={() => formik.setFieldValue('agent', undefined)} className="btn btn-light-danger btn-shadow ms-2">ลบ</span>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-12 mt-2">
                  <label className="form-label">ยอดเงินกู้(บาท)</label>
                  <input disabled={true} className="form-control" value={commafy(formik.values?.amount)}/>
                </div>

                <div className="col-lg-6 mt-2">
                  <label className="form-label">ยอดเงินกู้ทั้งหมด(บาท)</label>
                  <input disabled={true}  className="form-control" value={commafy(formik.values?.total)} />
                </div>
                
                <div className="col-lg-3 mt-2">
                  <label className="form-label">ดอกเบี้ย/เดือน</label>
                  <NumberFormat suffix="%" disabled={true}  className="form-control" value={commafy(formik.values?.percent)} />
                </div>
                
                <div className="col-lg-3 mt-2">
                  <label className="form-label">เดือน</label>
                  <input disabled={true}  className="form-control" value={formik.values?.months} />
                </div>
                
                <div className="col-lg-6 mt-2">
                  <label className="form-label">วันที่เริ่มจ่าย</label>
                  <input disabled={true} className="form-control" value={dateFormat(formik.values?.startDate)} />
                </div>

                <div className="col-lg-6 mt-2">
                  <label className="form-label">วันที่เริ่มสร้างสัญญา</label>
                  <input disabled={true} className="form-control" value={dateTimeFormat(formik.values?.created)} />
                </div>

                <div className="col-lg-12 mt-2">
                  <label className="form-label">ชื่อสัญญา</label>
                  <input {...formik.getFieldProps('name')} className="form-control" placeholder="นามบริษัท, นามบุคคล" />
                </div>

                <div className="col-lg-12 mt-2">
                  <label className="form-label">รายละเอียดเพิ่มเติม</label>
                  <textarea {...formik.getFieldProps('description')} className="form-control" rows={3} placeholder="รายละเอียดเพิ่มเติม"/>
                </div>

                <div className="col-lg-12 mt-5">
                  <label className="form-label">เอกสาร</label>
                  <div className="w-100 position-relative h-300px overflow-auto border border-gray-300 border-dashed rounded" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                      <div className="z-index-1 opacity-75 d-flex position-absolute h-100 w-100 justify-content-center opacity-2 align-items-center bg-light-primary">
                        <h3>วางเพื่ออัปโหลดไฟล์</h3>
                      </div> : <></>
                    }
                    { formik.getFieldProps('files').value.length > 0 ?
                      <>
                        <div className="d-flex flex-wrap flex-rows">
                          {formik.getFieldProps('files').value.map((f: any, i: number) => {
                            return <div className="col-12 col-lg-4" key={i} style={{zIndex: 99}}>
                              <div 
                                className="card cursor-pointer shadow m-2 d-flex flex-row"
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  if (typeof f.file == 'object') {
                                    setTimeout(() => {
                                      const url = URL.createObjectURL(f.file)
                                      setUrlDoc({
                                        file: f,
                                        name: f.name,
                                        url,
                                      })
                                    }, 0)
                                  } else {
                                    const url = await getUrlDoc(f.file)
                                    setUrlDoc({
                                      file: f,
                                      name: f.name,
                                      url
                                    })
                                  }
                                }}
                              >
                                <div className="m-2">
                                  <KTSVG className="svg-icon-muted svg-icon-3hx" path='/media/icons/duotone/Files/File.svg'/>
                                </div>
                                <div className="mt-2 flex-grow-1 text-truncate">
                                  { i===edit ?
                                    <input style={{display: 'inline-block'}} autoFocus {...formik.getFieldProps(`files[${i}].name`)} onBlur={() => setEdit(-1)} onClick={(e) => e.stopPropagation()} />
                                    : <div style={{display: 'inline-block'}} className="cursor-edit fs-5 fw-bolder">
                                      <div className="d-flex align-items-center">
                                        <span onClick={(e) => {
                                          e.stopPropagation()
                                          setEdit(i)
                                        }}>{formik.getFieldProps(`files[${i}]`).value.name}</span>
                                      </div>
                                    </div>
                                  }
                                </div>
                                <div className="d-flex align-items-center cursor-pointer mx-2" onClick={(e) => {
                                      e.stopPropagation()
                                      const temp = formik.getFieldProps('files').value.filter((v: any, ii: number) => i !== ii)
                                      formik.setFieldValue('files', temp);
                                    }}>
                                  <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-1' />
                                </div>
                              </div>
                            </div>
                          })}
                        </div>
                      </> : <>
                        <div className="d-flex position-absolute h-100 w-100 justify-content-center opacity-2 align-items-center">
                          <h3>เอกสาร</h3>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="col-lg-12 d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => formik.submitForm()}>บันทึก</button>
        </div>
      </div>
    </div>
    <SearchUserModal show={!closeFunderList} 
      filter={{isFunder: true}}
      handleClose={() => {setCloseFunderList(true)}}
      handleSubmit={(u) => {
        setCloseFunderList(true)
        formik.values.funder = u
      }} ></SearchUserModal>
    <SearchUserModal show={!closeAgentList} 
      filter={{isAgent: true}}
      handleClose={() => {setCloseAgentList(true)}}
      handleSubmit={(u) => {
        setCloseAgentList(true)
        if (formik.values.funder && formik.values.funder.id !== u.id) formik.values.agent = u
      }} ></SearchUserModal>
    <Modal
      show={urlDoc !== undefined}
      
      fullscreen={true}
    >
      <Modal.Header>
        <Modal.Title>
          {urlDoc?.name}
        </Modal.Title>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setUrlDoc(undefined)}>
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </div>
      </Modal.Header>
      <Modal.Body style={{ width: '100%', overflow: 'scroll'}}>
        {
          urlDoc ?
            !failLoadPDF ? <Document
              file={urlDoc.url}
              onLoadSuccess={({numPages}) => {
                setNumPdfPages(numPages)
              }}
              onLoadError={(e) => {
                console.log(e);
                setFailLoadPDF(true)
              }}
            >
              {
                Array.from(
                  new Array(numPdfPages),
                  (el, index) => (
                    <Page
                      className="my-2"
                      scale={1}
                      width={width}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ),
                )
              }
            </Document> : 
            <iframe title='doc' src={urlDoc.url} style={{ width: '100%', height: '100%'}} /> : <></>
        }
      </Modal.Body>
    </Modal>
  </>
}

export {ContractDetail}
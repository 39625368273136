import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGenderless } from '@fortawesome/free-solid-svg-icons'
import { deletePaymentHistory, getLogs } from './ContractApi'
import axios from 'axios'
import { dateFormat, getTime } from '../../utils/Utils'
import commafy from 'commafy';
import { KTSVG } from '../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'

export interface ContractLogRef {
  reload(): void;
}

export interface ContractLogProps {
  className?: string,
  contract: number,
  reload(): void
}

const ContractLog = forwardRef<
  ContractLogRef, ContractLogProps
>((props, ref) => {
  const [show, setShow] = useState<'log' | 'total'>('log')
  const [data, setData] = useState<any>()
  const [paymentData, setPaymentData] = useState<any>()
  useEffect(() => {
    if (data === undefined) {
      getLogs(props.contract, axios.CancelToken.source().token).then(({data}) => {
        setData(data.log)
        setPaymentData(data.payment)
      })
    }
  })
  useImperativeHandle(ref, () => ({
    reload() {
      getLogs(props.contract, axios.CancelToken.source().token)
        .then(({data}) => {
          setData(data.log)
          setPaymentData(data.payment)
        })
    }
  }));

  if (data === undefined) return <>loading</>
  let list, listPayment;
  if (data !== undefined) {
    list = data.reduce((acc: {
      date: string,
      list: any[],
    }[], cur: any) => {
      const i = acc.findIndex(a => a.date === dateFormat(cur.created));
      if (i !== -1) {
        acc[i].list.push(cur);
      } else {
        acc.push({
          date: dateFormat(cur.created),
          list: [cur],
        });
      }
      return acc;
    }, [])
  }
  
  if (paymentData !== undefined) {
    listPayment = paymentData.reduce((acc: {
      date: string,
      list: any[],
    }[], cur: any) => {
      const i = acc.findIndex(a => a.date === dateFormat(cur.created));
      if (i !== -1) {
        acc[i].list.push(cur);
      } else {
        acc.push({
          date: dateFormat(cur.created),
          list: [cur],
        });
      }
      return acc;
    }, [])
  }

  console.log(listPayment);
  
  return <>
    <div className={`card ${props.className}`}>
      <div className="card-header">
        {/* <div className="card-title nav-item">ประวัติ</div> */}
        <div className="card-title">
          <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
            <li className="nav-item cursor-pointer" onClick={() => setShow('log')}>
              <div
                className={`nav-link ${show === "log" ? 'text-dark active' : ''}`}>
                ประวัติ
              </div>
            </li>
            <li className="nav-item cursor-pointer" onClick={() => setShow('total')}>
              <div
                className={`nav-link ${show === "total" ? 'text-dark active' : ''}`}>
                การชำระเงินต้น
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-body">
        {
          show === 'log' &&
            <div className='timeline-label'>
              {
                list && list.map((d: any, i: number) => {
                  return <React.Fragment key={`${i}`}>
                    <div className='timeline-item'>
                      {/* begin::Label */}
                      <div className='timeline-label fw-bolder text-gray-800 fs-6'></div>
                      {/* end::Label */}
                      {/* begin::Badge */}
                      <div className='timeline-badge'>
                        {/* <FontAwesomeIcon className="text-warning fs-1" icon={faGenderless} /> */}
                      </div>
                      {/* end::Badge */}
                      {/* begin::Text */}
                      <div className='fw-mormal timeline-content text-muted ps-3 align-self-center'>
                        <div className="d-flex flex-rows align-items-center">
                          <div className="separator border-2 flex-grow-1"></div>
                          <div className="mx-2">{d.date}</div>
                          <div className="separator border-2 flex-grow-1"></div>
                        </div>
                      </div>
                      {/* end::Text */}
                    </div>
                    {
                      d.list.map((l: any, j: number) => {
                        return <div key={`n${i}-${j}`} className='timeline-item'>
                          {/* begin::Label */}
                          <div className='timeline-label fw-bolder text-gray-800 fs-6'>{getTime(l.created)}</div>
                          {/* end::Label */}
                          {/* begin::Badge */}
                          <div className='timeline-badge'>
                            <FontAwesomeIcon color={l.color} className="fs-1" icon={faGenderless} />
                          </div>
                          {/* end::Badge */}
                          {/* begin::Text */}
                          <div className='fw-mormal timeline-content text ps-3'>
                            {l.message}
                          </div>
                          {/* end::Text */}
                        </div>
                      })
                    }
                  </React.Fragment>
                })
              }
            </div>
        }
        {
          show === 'total' &&
          <div className='timeline-label'>
            {
              listPayment && listPayment.map((d: any, i: number) => {
                return <React.Fragment key={`${i}`}>
                  <div className='timeline-item'>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-6'></div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      {/* <FontAwesomeIcon className="text-warning fs-1" icon={faGenderless} /> */}
                    </div>
                    {/* end::Badge */}
                    {/* begin::Text */}
                    <div className='fw-mormal timeline-content text-muted ps-3 align-self-center'>
                      <div className="d-flex flex-rows align-items-center">
                        <div className="separator border-2 flex-grow-1"></div>
                        <div className="mx-2">{d.date}</div>
                        <div className="separator border-2 flex-grow-1"></div>
                      </div>
                    </div>
                    {/* end::Text */}
                  </div>
                  {
                    d.list.map((l: any, j: number) => {
                      return <div key={`n${i}-${j}`} className='timeline-item' style={{display: 'flex', alignItems: 'center'}}>
                        {/* begin::Label */}
                        <div className='timeline-label fw-bolder text-gray-800 fs-6'>{getTime(l.created)}</div>
                        {/* end::Label */}
                        {/* begin::Badge */}
                        <div className='timeline-badge'>
                          <FontAwesomeIcon color={l.color} className="fs-1" icon={faGenderless} />
                        </div>
                        {/* end::Badge */}
                        {/* begin::Text */}
                        <div className='fw-mormal timeline-content text text-nowarp ps-3'>
                          ชำระเงินต้น <span className=''>{commafy(l.amount)} บาท</span>
                          <div className={`btn btn-sm btn-icon btn-color-danger btn-active-light-danger cursor-pointer`}
                            onClick={() => {
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <div className='card shadow-sm'>
                                        <div className="card-body">
                                          <h3 className="card-title">ยกเลิกการชำระเงินต้น</h3>
                                          <div className="d-flex justify-content-end card-toolbar mt-4">
                                            <button onClick={() => {
                                              toast.promise(deletePaymentHistory(props.contract, l.id, axios.CancelToken.source().token).then(() => props.reload()), {
                                                pending: 'กำลังยกเลิกการชำระเงินต้น',
                                                success: 'ยกเลิกกำชำระเงินต้นสำเร็จ',
                                                error: 'เกิดข้อผิดพลาด'
                                              }).finally(() => onClose());
                                            }} className="btn btn-active-light-danger">ตกลง</button>
                                            <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                          </div>
                                        </div>
                                    </div>
                                  );
                                }
                              });
                            }}
                          >
                            <KTSVG path={'/media/icons/duotone/Text/Undo.svg'} className='svg-icon-2' />
                          </div>
                        </div>
                        {/* end::Text */}
                      </div>
                    })
                  }
                </React.Fragment>
              })
            }
          </div>
        }
      </div>
    </div>
  </>
});

export {ContractLog}
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import {Modal} from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'
import { getCustomerList } from '../../pages/customer/CustomerApi'
import { getProfilePhoto } from '../../utils/Utils'

type Props = {
  filter?: any,
  title?: string
  show: boolean
  handleSubmit?: (users: ICustomerDetails) => void
  handleClose: () => void
}

const SelectCustomerModal: React.FC<Props> = ({filter, title, show, handleClose, handleSubmit}) => {
  const [searchText, setSearchText] = useState<string>();
  const [data, setData] = useState<{total: number, list: Array<ICustomerDetails>}>()
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    getCustomerList({
      filter,
      limit: 5,
      search: searchText,
    }, cancelTokenSource.token)
      .then((res) => {
        setData(res.data)
      })
      .catch(() => {})
    return () => cancelTokenSource.cancel()
  }, [searchText, filter]);
  return (
    <Modal
      // className='bg-white'
      aria-hidden='true'
      dialogClassName='mw-650px h-auto'
      show={show}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>{title}</h1>
          </div>

          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className='form-control form-control-solid mb-8'
            placeholder='ค้นหา...'/>

          <div className='mb-10'>

            <div className='mh-300px scroll-y me-n7 pe-7'>
              {data?.list.map((user, i) => {
                return (
                  <div
                    className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                    key={i}
                  >
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px symbol-circle'>
                        <img alt='Pic' src={getProfilePhoto(null)} />
                      </div>

                      <div className='ms-5'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                        >
                          {user.fullname}
                        </a>
                        <div className='fw-bold text-dark'>{user.identificationId}</div>
                      </div>
                    </div>

                    <div className=''>
                      <button onClick={() => { if(handleSubmit) handleSubmit(user) }} className="btn btn-active-light-primary">เลือก</button>
                    </div>
                  </div>
                )
              })}
              {(!data || data?.list.length === 0 )&& <>ไม่พบข้อมูล</>}
            </div>
          </div>

          <div className='d-flex flex-stack'>
            <div className='me-5 fw-bold'>
            </div>
            <label className='form-check form-switch form-check-custom form-check-solid'>
              
            </label>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {SelectCustomerModal}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { actions } from '../modules/global-filter'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../setup';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OverrideDatePickerUtils } from '../utils/OverrideDatePickerUtils';
import moment, { Moment } from 'moment';

export declare interface GlobalFilterProps {
  onDateChange?(date: Moment): void
}

const GlobalFilter = (props: GlobalFilterProps) => {
  const dispatch = useDispatch()
  const filter = useSelector((s: RootState) => s.globalFilter.filter);
  const [date, setDate] = useState(moment());
  const [moreSelectTime, setMoreSelectTime] = useState(false);

  let type: Array<'year' | 'month'>

  if (filter === 'year') {
    type = ['year']
  } else if (filter === 'month') {
    type = ['year', 'month']
  }

  const transitionStyles = {
    entering: {transition: `opacity 300ms ease-in-out`, opacity: 0, display: 'none'},
    entered:  {opacity: 1},
    exiting:  {transition: `opacity 300ms ease-in-out`, opacity: 0},
    exited:  {display: 'none'},
  } as {[key: string]: React.CSSProperties};

  const transitionStyles2 = {
    entering: {transition: `opacity 300ms ease-in-out`, opacity: 0, display: 'none'},
    entered:  {opacity: 1},
    exiting:  {transition: `opacity 300ms ease-in-out`, opacity: 0},
    exited:  {display: 'none'},
  } as {[key: string]: React.CSSProperties};

  return <>
    <ul className='nav'>
      <Transition in={!moreSelectTime} timeout={300}>
        {
          (state: string) => (
            <>
              <li className='nav-item' style={transitionStyles[state]}>
                <a
                  onClick={() => dispatch(actions.setFilter({filter: 'week'}))} 
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${filter==='week'?'active':''}`}>
                  สัปดาห์
                </a>
              </li>
              <li className='nav-item' style={transitionStyles[state]}>
                <a
                  onClick={() => {
                    dispatch(actions.setFilter({filter: 'month'}))
                    setMoreSelectTime(true)
                  }} 
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${filter==='month'?'active':''}`}>
                  เดือน
                </a>
              </li>
              <li className='nav-item' style={transitionStyles[state]}>
                <a
                  onClick={() => {
                    dispatch(actions.setFilter({filter: 'year'}))
                    setMoreSelectTime(true)
                  }} 
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${filter==='year'?'active':''}`}>
                  ปี
                </a>
              </li>
              <li className='nav-item' style={transitionStyles[state]}>
                <a
                  onClick={() => dispatch(actions.setFilter({filter: 'total'}))} 
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${filter==='total'?'active':''}`}>
                  ทั้งหมด
                </a>
              </li>
            </>
          )
        }
      </Transition>
      <Transition in={moreSelectTime} timeout={300}>
        {
            (state: string) => (
              <>
                <MuiPickersUtilsProvider libInstance={moment} utils={OverrideDatePickerUtils} locale={'th'} >
                  <DatePicker
                    className="form-control"
                    disableToolbar={true}
                    hiddenLabel={true}
                    inputVariant={'outlined'}
                    autoOk={true}
                    views={type}
                    value={date} onChange={(d) => {
                      if (d) setDate(d)
                      if (props.onDateChange) props.onDateChange(date)
                      dispatch(actions.setFilter({filter, options: {additionalDate: d}}))
                    }}
                    TextFieldComponent={(props) =>  <li className='nav-item' style={transitionStyles2[state]}>
                    <div onClick={props.onClick}
                      className={`nav-link btn btn-sm btn-light-primary btn-active-primary fw-bolder px-4 me-1`}>
                      {/* <TextField onClick={props.onClick} value={props.value} InputProps={{disableUnderline: true}} /> */}
                      {String(props.value)}
                    </div>
                  </li>}
                  />
                </MuiPickersUtilsProvider>
                <li className='nav-item' style={transitionStyles2[state]}>
                  <a
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 active`}>
                    {filter==='month'&&'เดือน'}
                    {filter==='year'&&'ปี'}
                  </a>
                </li>
                <div style={transitionStyles2[state]} onClick={() => {
                  setMoreSelectTime(false)
                  setDate(moment())
                }}>
                  <div className="d-flex align-items-center mx-2 cursor-pointer h-100">
                    <FontAwesomeIcon className="svg-icon svg-icon-1" icon={faTimes} />
                  </div>
                </div>
              </>
            )
        }
      </Transition>
  </ul>
  </>
}

export {GlobalFilter}
import { Field } from 'formik';
import React, { FC } from 'react';
import { getIconContractType } from '../../utils/Utils';

const SelectContractType: FC = () => {
  return <>
    <div className='w-100 mb-0 d-flex justify-content-around'>
      <div className="col-xs-6">
        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(1)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  จำนอง
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(2)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  จำนำ
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(3)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  จำนำ (ปลอด)
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='3' />
            </span>
          </label>
        </div>
      </div>

      <div className="col-xs-6">
        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(4)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  ขายฝาก
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='4' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(5)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  ซื้อมาขายกลับ
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='5' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  {getIconContractType(6)}
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  เงินกู้
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom'>
              <Field className='form-check-input' type='radio' name='contractType' value='6' />
            </span>
          </label>
        </div>
      </div>
    </div>
  </>
}

export {SelectContractType}
import axios from 'axios';
import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { dateFormat, dateTimeFormat, getArrSize } from '../../utils/Utils';
import { deleteContract, getContractList, updateOrders } from './ContractApi';
import { KTSVG } from '../../../_metronic/helpers';
import commafy from 'commafy';
import moment from 'moment';
import { PageTitle } from '../../../_metronic/layout/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { getUserList } from '../user/UserApi';
import { actions } from '../../modules/contract-filter';

interface Props {
  syncGlobalFilter?: boolean
} 

const TableContract: FC<Props> = ({syncGlobalFilter}) => {
  const dispatch = useDispatch()
  const auth = useSelector((s: RootState) => s.auth);
  const funder = useSelector((s: RootState) => s.contractFilter.funder);
  console.log(funder)
  const filter = useSelector((s: RootState) => s.contractFilter.filter);
  console.log(filter.funder)
  const dateRangeFilter = useSelector((s: RootState) => s.contractFilter.dateRangeFilter);
  const searchText = useSelector((s: RootState) => s.contractFilter.searchText);
  const offset = useSelector((s: RootState) => s.contractFilter.offset);
  // const [searchText, setSearchText] = useState('');
  const [limit] = useState(10);
  // const [offset, setOffset] = useState(0);
  // const [dateRangeFilter, setDateRangeFilter] = useState<'week' | 'month' | 'year' | 'total'>('total');
  // const [filter, setFilter] = useState<{
  //   paidStatus: Array<string>
  //   status: Array<string>
  //   type: Array<string>
  //   funder: Array<number>
  //   from?: string
  //   to?: string
  // }>({
  //   paidStatus: [],
  //   status: [],
  //   type: [],
  //   funder: [],
  // });

  const setFilter = (filter: any) => {
    dispatch(actions.setContractFilter(filter))
  }
  const setDateRangeFilter = (filter: any) => {
    dispatch(actions.setDateRangeFilter(filter))
  }
  const setSearchText = (filter: any) => {
    dispatch(actions.setSearchText(filter))
  }
  const setOffset = (filter: any) => {
    dispatch(actions.setOffset(filter))
  }
  const setFunder = (filter: any) => {
    dispatch(actions.setFunder(filter))
  }

  const [sort, setSort] = useState<{
    orders?: number,
    id?: number,
  }>({
  })
  const [data, setData] = useState<{
    total: number,
    list: IContractDetails[],
  }>()
  const globalFilter = useSelector((s: RootState) => s.globalFilter)
  let { def } = useParams<{def: string}>();
  // const [funder, setFunder] = useState<ResponseGetUserList>();
  
  useEffect(() => {
    console.log(def)
    if (def === 'close') {
      setFilter({...filter, ...{status: ['2']}})
    } else {
      setFilter({...filter, ...{status: ['1']}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [def])

  useEffect(() => {
    if (funder) return
    getUserList({
      filter: {
        isFunder: true,
      }
    }, axios.CancelToken.source().token).then(({data}) => setFunder(data))
  })
  
  useEffect(() => {
    if (syncGlobalFilter === true) {
      let p,s,f;
      if (globalFilter.paidStatus) {
        if (globalFilter.paidStatus === '1') {
          p = {paidStatus: ['1']}
        } else if (globalFilter.paidStatus === '3') {
          p = {paidStatus: ['3']}
        } 
      } else {
        p = {paidStatus: []}
      }
      if (globalFilter.status) {
        if (globalFilter.status === '1') {
          s = {status: ['1']}
        } else if (globalFilter.status === '2') {
          s = {status: ['2']}
        } 
      } else {
        s = {status: []}
      }
      if (globalFilter.filter) {
        if (globalFilter.filter === 'year') {
          const from = moment().startOf('year').utc().format("YYYY-MM-DD HH:mm:ss");
          const to = moment().endOf('year').utc().format("YYYY-MM-DD HH:mm:ss");
          f = {from, to}
        } else if (globalFilter.filter === 'month') {
          const from = moment().startOf('month').utc().format("YYYY-MM-DD HH:mm:ss");
          const to = moment().endOf('month').utc().format("YYYY-MM-DD HH:mm:ss");
          f = {from, to}
        } else if (globalFilter.filter === 'week') {
          const from = moment().startOf('week').utc().format("YYYY-MM-DD HH:mm:ss");
          const to = moment().endOf('week').utc().format("YYYY-MM-DD HH:mm:ss");
          f = {from, to}
        }
      }
      setFilter({...filter, ...p, ...s, ...f})
      setDateRangeFilter(globalFilter.filter)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncGlobalFilter, globalFilter]);
  
  useEffect(() => {
    const call = axios.CancelToken.source()
    getContractList({search: searchText, limit, offset, filter, sort}, call.token)
      .then(({data}) => {
        setData(data);
      })
      .catch((e) => {});
    return () => call.cancel();
  }, [limit, offset, searchText, filter, sort]);

  const getList = (): Array<IContractDetails> => {
    return data?.list?data.list:[];
  }

  function getPaidStatus(c: IContractDetails) {
    if (c.paidStatus === 1) {
      return <><span className='text-danger'>ล่าช้า</span></>
    } else if (c.paidStatus === 2) {
      return <><span className='text-success'>ชำระแล้ว</span></>
    } else if (c.paidStatus === 3) {
      return <>รอชำระ</>
    }
  }

  const handlePaidStatusFilter: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setFilter({...filter, ...{paidStatus: [...filter.paidStatus, ...[e.target.value]]}})
    } else {
      setFilter({...filter, ...{paidStatus: filter.paidStatus.filter(v => v !== e.target.value)}})
    }
  }

  const handleStatusFilter: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setFilter({...filter, ...{status: [...filter.status, ...[e.target.value]]}})
    } else {
      setFilter({...filter, ...{status: filter.status.filter(v => v !== e.target.value)}})
    }
  }

  const handleTypeFilter: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setFilter({...filter, ...{type: [...filter.type, ...[e.target.value]]}})
    } else {
      setFilter({...filter, ...{type: filter.type.filter(v => v !== e.target.value)}})
    }
  }

  const getLastestInstallment = (i?: Installment) => {
    if (i) {
      return <>
        {i.order} - {dateFormat(i.paymentDate)}
      </>
    }
    return <></>
  }
  return <>
    <PageTitle breadcrumbs={[]}>ข้อมูลสัญญา</PageTitle>
    {
      (auth.user?.isAdmin || auth.user?.types.isAgent) &&
      <Select
        className="basic-single my-5"
        placeholder="นายทุน..."
        isLoading={funder === undefined}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        name="fullname"
        options={funder?funder.list.map((l) => ({value: l.id, label: l.fullname})):[]}
        onChange={async (v) => {
          setFilter({...filter, ...{funder: v.map<number>(d => d.value?d.value:0)}})
        }}
        defaultValue={auth.user?.types.isFunder ? 
          {value: auth.user?.id, label: auth.user?.fullname}:
          filter.funder.map(v => ({
            value: v,
            label: funder?.list?.find(f => f.id === v)?.fullname,
          }))
        }
      />
    }
    <div className="card">
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          <div className="d-flex align-items-center position-relative my-1">
            <span className="svg-icon svg-icon-1 position-absolute ms-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
              </svg>
            </span>
            <input value={searchText} onChange={(e) => {setSearchText(e.target.value)}} 
              type="text" className="form-control w-300px ps-15" 
              placeholder="ค้นหา..." />
          </div>
        </div>
        <div className="card-toolbar">
          <ul className='nav'>
            <li className='nav-item'>
              <span onClick={() => {
                const from = moment().startOf('week').utc().format("YYYY-MM-DD HH:mm:ss");
                const to = moment().endOf('week').utc().format("YYYY-MM-DD HH:mm:ss");
                setFilter({...filter, ...{from, to}})
                setDateRangeFilter('week')
              }} className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${dateRangeFilter==='week'?'active':''}`}>
                สัปดาห์นี่
              </span>
            </li>
            <li className='nav-item'>
              <span onClick={() => {
                const from = moment().startOf('month').utc().format("YYYY-MM-DD HH:mm:ss");
                const to = moment().endOf('month').utc().format("YYYY-MM-DD HH:mm:ss");
                setFilter({...filter, ...{from, to}})
                setDateRangeFilter('month')
              }} className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${dateRangeFilter==='month'?'active':''}`}>
                เดือนนี้
              </span>
            </li>
            <li className='nav-item'>
              <span onClick={() => {
                const from = moment().startOf('year').utc().format("YYYY-MM-DD HH:mm:ss");
                const to = moment().endOf('year').utc().format("YYYY-MM-DD HH:mm:ss");
                setFilter({...filter, ...{from, to}})
                setDateRangeFilter('year')
              }} className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${dateRangeFilter==='year'?'active':''}`}>
                ปีนี้
              </span>
            </li>
            <li className='nav-item'>
              <span onClick={() => {
                setFilter({...filter, ...{from: undefined, to: undefined}})
                setDateRangeFilter('total')
              }} className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 ${dateRangeFilter==='total'?'active':''}`}>
                ทั้งหมด
              </span>
            </li>
          </ul>

          <button
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
              className='svg-icon-2'
            />
          </button>
          <div id="filter" className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>คัดกรอง</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>

              <div className='mb-10'>
                <label className='form-label fw-bold'>การชำระ:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom me-5'>
                    <input className='form-check-input' checked={filter.paidStatus.some(p => p === '1')} onChange={handlePaidStatusFilter} name="paidStatus" type='checkbox' value='1' />
                    <span className='form-check-label'>ล่าช้า</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom me-5'>
                    <input className='form-check-input' checked={filter.paidStatus.some(p => p === '3')} onChange={handlePaidStatusFilter} name="paidStatus" type='checkbox' value='3' />
                    <span className='form-check-label'>รอการชำระ</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.paidStatus.some(p => p === '2')} onChange={handlePaidStatusFilter} name="paidStatus" type='checkbox' value='2' />
                    <span className='form-check-label'>ชำระแล้ว</span>
                  </label>
                </div>
              </div>

              <div className='mb-10'>
                <label className='form-label fw-bold'>สถานะ:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom me-5'>
                    <input className='form-check-input' checked={filter.status.some(p => p === '1')} onChange={handleStatusFilter} type='checkbox' value='1' />
                    <span className='form-check-label'>ปกติ</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.status.some(p => p === '2')} onChange={handleStatusFilter} type='checkbox' value='2' />
                    <span className='form-check-label'>ปิดบัญชี</span>
                  </label>
                </div>
              </div>

              <div className='mb-10'>
                <label className='form-label fw-bold'>ประเภทสัญญา:</label>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom me-5'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '1')} onChange={handleTypeFilter} type='checkbox' value='1' />
                    <span className='form-check-label'>จำนอง</span>
                  </label>
                </div>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '2')} onChange={handleTypeFilter} type='checkbox' value='2' />
                    <span className='form-check-label'>จำนำ</span>
                  </label>
                </div>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '3')} onChange={handleTypeFilter} type='checkbox' value='3' />
                    <span className='form-check-label'>จำนำ (ปลอด)</span>
                  </label>
                </div>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '4')} onChange={handleTypeFilter} type='checkbox' value='4' />
                    <span className='form-check-label'>ขายฝาก</span>
                  </label>
                </div>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '5')} onChange={handleTypeFilter} type='checkbox' value='5' />
                    <span className='form-check-label'>ซื้อมาขายกลับ</span>
                  </label>
                </div>

                <div className='d-flex my-1'>
                  <label className='form-check form-check-sm form-check-custom'>
                    <input className='form-check-input' checked={filter.type.some(p => p === '6')} onChange={handleTypeFilter} type='checkbox' value='6' />
                    <span className='form-check-label'>เงินกู้</span>
                  </label>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-white btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                  onClick={() => {setFilter({
                    paidStatus: [],
                    status: [],
                    type: [],
                    funder: [],
                  })}}
                >
                  คืนค่า
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body py-2">
        <div className="table-responsive">
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='cursor-pointer' onClick={() => {
                    if (sort.orders === undefined) {
                      setSort({orders: -1})
                    } else if (sort.orders === -1) {
                      setSort({orders: 1})
                    } else if (sort.orders === 1) {
                      setSort({orders: undefined})
                    }
                  }}>
                  <div className="d-flex">
                    <span>#</span>
                    {sort.orders === -1 && <KTSVG path='/media/icons/duotone/Navigation/Up-2.svg'/>}
                    {sort.orders === 1 && <KTSVG path='/media/icons/duotone/Navigation/Down-2.svg'/>}
                  </div>
                </th>
                {/* <th className='cursor-pointer' onClick={() => {
                    if (sort.id === undefined) {
                      setSort({id: -1})
                    } else if (sort.id === -1) {
                      setSort({id: 1})
                    } else if (sort.id === 1) {
                      setSort({id: undefined})
                    }
                  }}>
                  <div className="d-flex">
                    <span>รหัส</span>
                    {sort.id === -1 && <KTSVG path='/media/icons/duotone/Navigation/Up-2.svg'/>}
                    {sort.id === 1 && <KTSVG path='/media/icons/duotone/Navigation/Down-2.svg'/>}
                  </div>
                </th> */}
                <th className='text-nowrap'>ประเภทสัญญา</th>
                <th>ชื่อสัญญา</th>
                <th className='text-end'>ยอดเงินกู้</th>
                <th className='text-center'>สถานะ</th>
                <th className='text-center'>งวดที่ - วันที่ชำระ</th>
                <th className='text-start d-none d-sm-table-cell'></th>
                <th className='text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                getList().map((c, i) => <tr key={i}>
                <td className=''>{c.orders}</td>
                {/* <td className=''>{c.id}</td> */}
                <td className=''>{c.contractTypeName}</td>
                <td className=''>
                  <div style={{width: '225px'}}>
                    <span className='d-inline-block text-truncate text-dark fw-bolder text-hover-primary fs-6' style={{width: '100%'}}>
                      {c.name}
                    </span>
                    <span className='d-inline-block text-truncate fw-bold text-muted d-block fs-7' style={{width: '100%'}}>
                      <span className='text-dark'>โดย: </span> <Link className="text-muted" to={`/customer/update/${c.customer?.id}`}>{c.customer?.fullname}</Link>
                    </span>
                  </div>
                </td>
                <td className='text-end text-nowrap'>
                  <span className='text-dark fw-bolder text-hover-primary fs-6'>
                    {commafy(c.amount)} บาท
                  </span>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {getPaidStatus(c)}
                  </span>
                </td>
                <td className='text-center'>
                  { c.status === 1 ?
                    <>
                      <span className='badge badge-light-success'>ปกติ</span>
                    </> : <>
                      <span className='badge badge-light-danger'>ปิดบัญชี</span>
                    </>
                  }
                </td>
                <td className='text-center text-nowrap'>
                  {getLastestInstallment(c.installment.find((i) => i.paidDate === null))}
                </td>
                <td className='text-start d-none d-sm-table-cell text-nowrap'>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <span className='text-dark'>สร้างเมื่อ: </span> <span className="text-muted">{dateTimeFormat(c.created)}</span>
                  </span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <span className='text-dark'>แก้ไขล่าสุด: </span> <span className="text-muted">{dateTimeFormat(c.updated)}</span>
                  </span>
                </td>
                <td className=''>
                  <div className="d-flex">
                    <div onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div className='card shadow-sm'>
                                <div className="card-body">
                                  <h3 className="card-title">เปลี่ยนลำดับสัญญา <b>{c.name}</b>&emsp;</h3>
                                  <form action="" onSubmit={async (e) => {
                                    e.preventDefault()
                                    const orders = Number.parseInt((e.currentTarget.elements.namedItem('orders') as HTMLInputElement).value)
                                    toast.promise(
                                      updateOrders(c, orders, axios.CancelToken.source().token)
                                      .then(() => getContractList({search: searchText, limit, offset, filter, sort}, axios.CancelToken.source().token)), {
                                      pending: 'กำลังปรับลำดับ',
                                      success: 'ปรับลำดับเรียบร้อย',
                                      error: 'เกิดข้อผิดพลาด',
                                    }).then(({data}) => setData(data))
                                    onClose()
                                  }}>
                                    <NumberFormat name="orders"
                                      className='form-control' placeholder="ลำดับ" allowNegative={false} decimalScale={0} />
                                    <div className="d-flex justify-content-end card-toolbar mt-4">
                                      <button type="submit" className="btn btn-active-light-primary">ตกลง</button>
                                      <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                    </div>
                                  </form>
                                </div>
                            </div>
                          );
                        }
                      });
                    }}
                      className='btn btn-icon btn-bg-light btn-color-dark-light btn-sm me-1'>
                      <KTSVG path='/media/icons/svg/sort-solid.svg' />
                    </div>
                    <Link to={`/contract/${c.id}`}
                      className='btn btn-icon btn-bg-light btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/General/Search.svg' />
                    </Link>
                    <Link to={`/contract/${c.id}/manage`}
                      className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/Shopping/Money.svg' />
                    </Link>
                    <div onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div className='card shadow-sm'>
                                <div className="card-body">
                                  <h3 className="card-title">ต้องการลบสัญญา&emsp;<b>{c.name}</b>&emsp;ใช่หรือไม่ ?</h3>
                                  <div className="d-flex justify-content-end card-toolbar mt-4">
                                    <span onClick={() => {
                                      toast.promise(deleteContract(c, axios.CancelToken.source().token)
                                        .then(() => getContractList({search: searchText, limit, offset, filter}, axios.CancelToken.source().token)), {
                                        success: 'ลบสัญญาเรียบร้อย',
                                        error: 'เกิดข้อผิดพลาด',
                                        pending: 'กำลังลบสัญญา'
                                      }).then(({data}) => {
                                        setData(data)
                                      })
                                      onClose()
                                    }} className="btn btn-active-light-danger">ตกลง</span>
                                    <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                  </div>
                                </div>
                            </div>
                          );
                        }
                      });
                    }}
                      className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/General/Trash.svg' />
                    </div>
                  </div>
                </td>
              </tr>) 
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6 fw-bold text-gray-700'>แสดง {data?.total!==0?offset+1:0} ถึง {(data?.total!==0?offset + limit:0)} ของ {data?.total} ทั้งหมด</div>
            <ul className='pagination'>
              <li className='page-item previous' onClick={() => {if((offset - limit) >= 0) setOffset(offset - limit)}}>
                <span className='page-link'>
                  <i className='previous'></i>
                </span>
              </li>
              {
                getArrSize(data?.total?(data.total/limit):0).map((i) => (
                  <li key={i} className={`page-item ${(offset/limit) === i?'active':''}`} onClick={() => setOffset(i * limit)}>
                    <span className='page-link'>{i + 1}</span>
                  </li>
                ))
              }
              <li className='page-item next' onClick={() => {if(data?.total && ((offset + limit) < data.total)) setOffset(offset + limit)}}>
                <span className='page-link'>
                  <i className='next'></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default TableContract;

import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { isHexColor } from '../utils/Utils'
import commafy from 'commafy';
import { ResponseProfit } from '../pages/dashboard/ReportApi';

export interface RevenueReport1Props extends React.HTMLAttributes<HTMLElement> {
  profit: ResponseProfit
}
const sColor = 'success';
const dColor = 'danger';
const pColor = 'primary';
const lColor = 'dark-light';
const RevenueReport1: React.FC<RevenueReport1Props> = (props) => {
  const successColor =  isHexColor(sColor)?sColor:getCSSVariableValue('--bs-' + sColor)
  const dangerColor =  isHexColor(dColor)?dColor:getCSSVariableValue('--bs-' + dColor)
  const primaryColor =  isHexColor(dColor)?dColor:getCSSVariableValue('--bs-' + pColor)
  const lightColor =  isHexColor(dColor)?dColor:getCSSVariableValue('--bs-' + lColor)
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartRef && props.profit === undefined) {
      return;
    }
    const chartState: ApexOptions = {
      chart: {
        type: 'pie',
      },
      labels: ['ดอกเบี้ยตามสัญญา', 'วงเงินกู้', 'ดอกเบี้ยที่ได้รับ', 'เงินต้นที่ได้รับ'],
      series: [
        props.profit.interrest.reduce((acc, cur) => cur += acc,0),
        props.profit.amount.reduce((acc, cur) => cur += acc,0),
        props.profit.paid_total,
        props.profit.payment_total,
      ],
      colors: [lightColor, dangerColor, successColor, primaryColor],
      tooltip: {
        y: {
          formatter: (v) => {
            return `${commafy(v)} บาท`;
          }
        }
      },
      dataLabels: {
        formatter: (v, opts) => {
          return commafy(opts.w.config.series[opts.seriesIndex]) + ' บาท'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '55%',
            labels: {
              show: false,
            }
          }
        }
      },
      legend: {
        show: true,
        position: 'right',
      }
    };

    const chart = new ApexCharts(chartRef.current, chartState);

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  })

  return <>
    <div {...props}>
      <div ref={chartRef}></div>
      <div
          className='mx-5 bg-white'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                จำนวนสัญญา
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{props.profit.created_contract}</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                จำนวนเงินกู้
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{commafy(props.profit.amount.reduce((acc, cur) => cur += acc, 0))} บาท</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                ดอกเบี้ยตามสัญญา
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{commafy(props.profit.interrest.reduce((acc, cur) => cur += acc, 0))} บาท</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                ดอกเบี้ยที่ได้รับมา
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{commafy(props.profit.paid_total)} บาท</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                เงินต้นที่ได้รับมา
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{commafy(props.profit.payment_total)} บาท</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
          {/* begin::Description */}
          <div className='d-flex align-items-center flex-wrap w-100'>
            {/* begin::Title */}
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                ดอกเบี้ยเฉลี่ย
              </span>
              <div className='text-gray-400 fw-bold fs-7'></div>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <div className='d-flex align-items-center'>
              <div className='fw-bolder fs-5 text-gray-800 pe-1'>{props.profit.average_percent.toFixed(2)}%</div>
            </div>
            {/* end::Label */}
          </div>
          {/* end::Description */}
        </div>
        {/* end::Item */}
      </div>      
    </div>
  </>
}

export {RevenueReport1}
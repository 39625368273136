import React, { FC, useCallback } from 'react';
import { useState } from 'react';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {useDropzone} from 'react-dropzone';
import { SearchUserModal } from '../../modules/select-user-modal/SelectUserModal';
import { getProfilePhoto } from '../../utils/Utils';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import { calInterrest } from '../../modules/interrest/interrest';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { KTSVG } from '../../../_metronic/helpers';
import NumberFormat from 'react-number-format';
import { OverrideDatePickerUtils } from '../../utils/OverrideDatePickerUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
moment.locale('th')

const ContractForm: FC = (props) => {
  const auth = useSelector((s: RootState) => s.auth);
  const [closeFunderList, setCloseFunderList] = useState(true)
  const [closeAgentList, setCloseAgentList] = useState(true)
  const [edit, setEdit] = useState(-1)
  const formik = useFormikContext<IContractDetails>()
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    formik.setFieldValue('files', formik.getFieldProps('files').value.concat(acceptedFiles.map((f: any) => ({
      name: f.name,
      file: f,
    }))))
  }, [formik])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const funder = formik.values.funder;
  const agent = formik.values.agent;
  if (funder && funder.id === agent?.id) formik.setFieldValue('agent', undefined);
  if (!(auth.user?.isAdmin && auth.user?.types.isFunder) && auth.user?.types.isAgent && agent === undefined) {
    formik.setFieldValue('agent', auth.user);
  }
  const amount = formik.values.amount?formik.values.amount:0
  const months = formik.values.months?formik.values.months:0
  const percent = formik.values.percent?formik.values.percent:0
  return <>
    <div className="row w-100">
      <div className='offset-lg-2 col-lg-8'>
        <div className="row">
          
          <div className="col-lg-6 mt-2">
            <label className="form-label">นายทุน</label>
            <div
              className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
              { funder ?
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px border'>
                    <img alt='Pic' src={getProfilePhoto(funder.profilePhoto)} />
                  </div>

                  <div className='ms-5'>
                    <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      {funder.username}
                    </span>
                    <div className='fw-bold text-dark'>{funder.fullname}</div>
                  </div>
                </div> : <div>
                  <div className='symbol symbol-45px'>
                    <img alt='Pic' src={getProfilePhoto(null)} />
                  </div>
                  <span className="m-2">กรุณาเลือกนายทุน</span>
                </div>
              }
              <div className="overlay-layer bg-dark bg-opacity-25">
                <span onClick={() => setCloseFunderList(false)} className="btn btn-primary btn-shadow">เลือก</span>
                <span onClick={() => formik.setFieldValue('funder', undefined)} className="btn btn-light-danger btn-shadow ms-2">ลบ</span>
              </div>
            </div>
          </div>
          
          <div className="col-lg-6 mt-2">
            <label className="form-label">นายหน้า</label>
            <div
              className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
              { agent ?
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px border'>
                    <img alt='Pic' src={getProfilePhoto(agent.profilePhoto)} />
                  </div>

                  <div className='ms-5'>
                    <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      {agent.username}
                    </span>
                    <div className='fw-bold text-dark'>{agent.fullname}</div>
                  </div>
                </div> : <div>
                  <div className='symbol symbol-45px'>
                    <img alt='Pic' src={getProfilePhoto(null)} />
                  </div>
                  <span className="m-2">กรุณาเลือกนายหน้า</span>
                </div>
              }
              {
                !(!(auth.user?.isAdmin && auth.user?.types.isFunder) && auth.user?.types.isAgent && agent) &&
                <div className="overlay-layer bg-dark bg-opacity-25">
                  <span onClick={() => setCloseAgentList(false)} className="btn btn-primary btn-shadow">เลือก</span>
                  <span onClick={() => formik.setFieldValue('agent', undefined)} className="btn btn-light-danger btn-shadow ms-2">ลบ</span>
                </div>
              }
            </div>
          </div>
          
          <div className="col-lg-6 mt-2">
            <label className="form-label">ยอดเงินกู้(บาท)</label>
            <NumberFormat onChange={(e) => {
              const n = Number.parseFloat(e.currentTarget.value.split(',').join(''))
              if (!isNaN(n)) formik.setFieldValue('amount', n)
            }} thousandSeparator={true} allowNegative={false} decimalScale={2} className="form-control" placeholder="บาท" />
          </div>
          
          <div className="col-lg-3 mt-2">
            <label className="form-label">ดอกเบี้ย/เดือน</label>
            <NumberFormat onChange={(e) => {
              const n = Number.parseFloat(e.currentTarget.value.split(',').join(''))
              if (!isNaN(n)) {
                formik.setFieldValue('percent', n)
              } else {
                formik.setFieldValue('percent', 0)
              }
            }} allowNegative={false} decimalScale={2} className="form-control" placeholder="%"
            suffix="%" />
          </div>
          
          <div className="col-lg-3 mt-2">
            <label className="form-label">เดือน</label>
            <NumberFormat onChange={(e) => {
              const n = Number.parseFloat(e.currentTarget.value.split(',').join(''))
              if (!isNaN(n)) formik.setFieldValue('months', n)
            }} allowNegative={false} decimalScale={0} className="form-control" placeholder="เดือน"/>
          </div>
          
          <div className="col-lg-6 mt-2">
            <label className="form-label">วันที่เริ่มจ่าย</label>
            {/* <input type='date' className="form-control" placeholder="วันที่เริ่มจ่าย"/> */}
            <MuiPickersUtilsProvider libInstance={moment} utils={OverrideDatePickerUtils} locale={'th'} >
              <DatePicker
                  InputProps={{ disableUnderline: true }}
                  style={{padding: '0.75rem 1rem', border: '1px solid #E4E6EF'}}
                  maxDate={'3000-01-01'}
                  autoOk={true}
                  variant="inline"
                  format="DD MMM YYYY"
                  className="form-control"
                  value={formik.values.startDate}
                  onChange={(d: MaterialUiPickersDate) => {
                    formik.setFieldValue('startDate', d);
                  }}
                />
            </MuiPickersUtilsProvider>
          </div>
          
          <div className="col-lg-3 mt-2">
            <label className="form-label">ดอกเบี้ย/เดือน</label>
            <NumberFormat thousandSeparator={true} value={calInterrest(amount, percent).toFixed(2)} className="form-control" placeholder='0 บาท' disabled={true}/>
          </div>
          
          <div className="col-lg-3 mt-2">
            <label className="form-label">รวมดอกเบี้ย</label>
            <NumberFormat thousandSeparator={true}  value={(calInterrest(amount, percent) * months).toFixed(2)} className="form-control" placeholder='0 บาท' disabled={true}/>
          </div>

          <div className="col-lg-6 mt-2">
            <label className="form-label">วันที่สร้างสัญญา</label>
            {/* <input type='date' className="form-control" placeholder="วันที่เริ่มจ่าย"/> */}
            <MuiPickersUtilsProvider libInstance={moment} utils={OverrideDatePickerUtils} locale={'th'} >
              <DateTimePicker
                  InputProps={{ disableUnderline: true }}
                  style={{padding: '0.75rem 1rem', border: '1px solid #E4E6EF'}}
                  maxDate={'3000-01-01'}
                  autoOk={true}
                  variant="inline"
                  format="DD MMM YYYY HH:mm"
                  className="form-control"
                  value={formik.values.created}
                  onChange={(d: MaterialUiPickersDate) => {
                    formik.setFieldValue('created', d);
                  }}
                />
            </MuiPickersUtilsProvider>
          </div>

          <div className="col-lg-12 mt-2">
            <label className="form-label">ชื่อสัญญา</label>
            <input {...formik.getFieldProps('name')} className="form-control" placeholder="นามบริษัท, นามบุคคล" />
          </div>
          
          <div className="col-lg-12 mt-2">
            <label className="form-label">รายละเอียดเพิ่มเติม</label>
            <textarea {...formik.getFieldProps('description')} className="form-control" rows={3} placeholder="รายละเอียดเพิ่มเติม"/>
          </div>
          
          <div className="col-lg-12 mt-5">
            <label className="form-label">เอกสาร</label>
            <div className="w-100 position-relative h-300px overflow-auto border border-gray-300 border-dashed rounded" {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                <div className="z-index-1 opacity-75 d-flex position-absolute h-100 w-100 justify-content-center opacity-2 align-items-center bg-light-primary">
                  <h3>วางเพื่ออัปโหลดไฟล์</h3>
                </div> : <></>
              }
              { formik.getFieldProps('files').value.length > 0 ?
                <>
                  <div className="d-flex flex-wrap flex-rows" onClick={(e) => {e.stopPropagation()}}>
                    {formik.getFieldProps('files').value.map((f: any, i: number) => {
                      return <div className="col-12 col-sm-6 col-lg-6" key={'f'+i}>
                        <div className="card shadow m-2 d-flex flex-row">
                          <div className="m-2">
                            <KTSVG className="svg-icon-muted svg-icon-3hx" path='/media/icons/duotone/Files/File.svg'/>
                          </div>
                          <div className="mt-2 flex-grow-1 text-truncate">
                            { i===edit ?
                              <input style={{display: 'inline-block'}} autoFocus {...formik.getFieldProps(`files[${i}].name`)} onBlur={() => setEdit(-1)} onClick={(e) => e.stopPropagation()} />
                              : <div style={{display: 'inline-block'}} className="cursor-edit fs-5 fw-bolder">
                                <div className="d-flex align-items-center">
                                  <span onClick={(e) => {
                                    e.stopPropagation()
                                    setEdit(i)
                                  }}>{formik.getFieldProps(`files[${i}]`).value.name}</span>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="d-flex align-items-center cursor-pointer mx-2" onClick={(e) => {
                                e.stopPropagation()
                                const temp = formik.getFieldProps('files').value.filter((v: any, ii: number) => i !== ii)
                                formik.setFieldValue('files', temp);
                              }}>
                            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-1' />
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                </> : <>
                  <div className="d-flex position-absolute h-100 w-100 justify-content-center opacity-2 align-items-center">
                    <h3>เอกสาร</h3>
                  </div>
                </>
              }
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <SearchUserModal show={!closeFunderList} 
      filter={{isFunder: true}}
      handleClose={() => {setCloseFunderList(true)}}
      handleSubmit={(u) => {
        setCloseFunderList(true)
        formik.values.funder = u
      }} ></SearchUserModal>
    <SearchUserModal show={!closeAgentList} 
      filter={{isAgent: true}}
      handleClose={() => {setCloseAgentList(true)}}
      handleSubmit={(u) => {
        setCloseAgentList(true)
        if (funder && funder.id !== u.id) formik.setFieldValue('agent', u)
      }} ></SearchUserModal>
  </>
}

export {ContractForm}
import axios from 'axios';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { SelectCustomerModal } from '../../modules/select-customer-modal/SelectCustomerModal';
import { getProfilePhoto } from '../../utils/Utils';
import { getCustomerList } from '../customer/CustomerApi';
// import { KTSVG } from '../../../_metronic/helpers';

const CustemerDetailForm: FC<{
  title: string,
  keyField: string,
}> = ({title, keyField}) => {
  const prefix = useSelector((selector: RootState) => selector.prefix)
  const [closeCustomerList, setCloseCustomerList] = useState(true)
  const [loadingCustomer, setLoadingCustomer] = useState(true)
  const formik = useFormikContext<IContractDetails>()

  useEffect(() => {
    if (keyField === 'customer') {
      const findPrefix = prefix.find((p) => {
        return p.id.toString() === formik.getFieldProps<ICustomerDetails>(keyField).value.prefix?.toString();
      })
      const fullname = `${findPrefix?.name}${formik.getFieldProps<ICustomerDetails>(keyField).value.firstname} ${formik.getFieldProps<ICustomerDetails>(keyField).value.lastname}`;
      formik.setFieldValue(`name`, fullname)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyField, prefix, formik.values.customer?.firstname, formik.values.customer?.lastname, formik.values.customer?.prefix])

  useEffect(() => {
    if (formik.getFieldProps<ICustomerDetails>(keyField).value &&
      !formik.getFieldProps<ICustomerDetails>(keyField).value?.id &&
      formik.getFieldProps<ICustomerDetails>(keyField).value?.identificationId.split('-').join('').split('_').join('').length >= 13) {
      const cancel = axios.CancelToken.source()
      setLoadingCustomer(true)
      getCustomerList({
        limit: 1,
        search: formik.getFieldProps<ICustomerDetails>(keyField).value?.identificationId.split('-').join('').split('_').join('')
      }, cancel.token).then(({data}) => {
        if (data.list.length === 1) {
          formik.setFieldValue(keyField, data.list[0]);
        }
      }).catch(() => {})
      .finally(() => {
        setLoadingCustomer(false)
      })
      return () => cancel.cancel('cancel')
    }
  }, [formik, keyField])
  const hasId = formik.getFieldProps(keyField).value?.id !== undefined
  return <>
    <div className="row w-100">
      <div className='offset-lg-2 col-lg-8'>
        <div className="row" data-kt-indicator={loadingCustomer?'on':'off'}>
          <div className="col-12">
            <h1>{title}</h1>
          </div>

          <div className="col-lg-6 mt-2">
            <div
              className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
              { hasId ?
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px border'>
                    <img alt='Pic' src={getProfilePhoto(formik.getFieldProps(keyField).value?.profilePhoto)} />
                  </div>

                  <div className='ms-5'>
                    <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      {formik.getFieldProps(keyField).value?.fullname}
                    </span>
                    <div className='fw-bold text-dark'>{formik.getFieldProps(keyField).value?.identificationId}</div>
                  </div>
                </div> : <div>
                  <div className='symbol symbol-45px'>
                    <img alt='Pic' src={getProfilePhoto(null)} />
                  </div>
                  <span className="m-2">กรุณาเลือกข้อมูล{keyField!=='customer'?'ผู้ค้ำ':'ลูกค้า'}</span>
                </div>
              }
              <div className="overlay-layer bg-dark bg-opacity-25">
                <span onClick={() => setCloseCustomerList(false)} className="btn btn-primary btn-shadow">เลือก</span>
                <span onClick={() => {
                  if (keyField === 'guarantor') {
                    formik.setFieldValue(keyField, {
                      id: undefined,
                      identificationId: '',
                      prefix: -1,
                      prefixName: '',
                      firstname: '',
                      lastname: '',
                      address: '',
                      phoneNumber: '',
                      facebook: '',
                      lineId: '',
                      email: '',
                    });
                  } else {
                    formik.setFieldValue(keyField, {
                      identificationId: '',
                      prefix: -1,
                      prefixName: '',
                      firstname: '',
                      lastname: '',
                      address: '',
                      phoneNumber: '',
                      facebook: '',
                      lineId: '',
                      email: '',
                    });
                  }
                }} className="btn btn-light-danger btn-shadow ms-2">ลบ</span>
              </div>
            </div>
          </div>

          <div className="d-flex mt-4 align-items-center">
            <div className="separator w-100"></div>
            <div className="mx-2 text-nowrap"><b>หรือกรอกข้อมูล</b></div>
            <div className="separator w-100"></div>
          </div>

          <div className="col-lg-12 mt-2">
            <label className="form-label">เลขบัตรประชาชน</label>
            <ReactInputMask disabled={hasId} {...formik.getFieldProps(`${keyField}.identificationId`)} mask="9-9999-9999-999-9" className="form-control" placeholder="_-____-____-___-_"/>
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">คำนำหน้า</label>
            <select
              disabled={hasId}
              className='form-select form-select-lg'
              {...formik.getFieldProps(`${keyField}.prefix`)}
            >
              <option value={-1}>กรุณาเลือกคำนำหน้า..</option>
              { prefix.map((p, i) => <option key={i} value={p.id}>{p.name}</option>) }
            </select> 
            { formik.getFieldProps(`${keyField}.prefix`).value === '0' && <>
                <div className="col-lg-4 w-100 mt-2">
                  <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.prefixName`)} className="form-control" placeholder="คำนำหน้าอื่นๆ"/>
                </div>
              </>
            }
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">ชื่อ</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.firstname`)} className="form-control" placeholder="ชื่อ"/>
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">นามสกุล</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.lastname`)} className="form-control" placeholder="นามสกุล"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">เบอร์โทรศัพท์</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.phoneNumber`)} className="form-control" placeholder="เบอร์โทรศัพท์"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">อีเมล์</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.email`)} className="form-control" placeholder="อีเมล์"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">Facebook</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.facebook`)} className="form-control" placeholder="Facebook"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">Line ID</label>
            <input disabled={hasId}  {...formik.getFieldProps(`${keyField}.lineId`)} className="form-control" placeholder="Line ID"/>
          </div>
          <div className="col-lg-12 mt-2">
            <label className="form-label">ที่อยู่</label>
            <textarea disabled={hasId} {...formik.getFieldProps(`${keyField}.address`)} className="form-control" placeholder="ที่อยู่"/>
          </div>
        </div>
      </div>
    </div>
    <SelectCustomerModal show={!closeCustomerList} 
      handleClose={() => setCloseCustomerList(true)}
      handleSubmit={(c) => {
        setCloseCustomerList(true)
        if (keyField === 'customer') {formik.setFieldValue('name', c.fullname)}
        formik.setFieldValue(keyField, c)
      }} ></SelectCustomerModal>
  </>
}

export {CustemerDetailForm}
import axios, { CancelToken } from 'axios'
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
export const CREATE_USER = `${API_URL}/v1/user/create`
export const UPDATE_USER = `${API_URL}/v1/user/update`
export const REMOVE_USER = `${API_URL}/v1/user/remove`
export const GET_USER_LIST = `${API_URL}/v1/user/list`
export const GET_USER = `${API_URL}/v1/user`
export const API_USER = `${API_URL}/v1/user`

export function getUser(userId: number) {
  return axios.get<IUserDetails>(`${GET_USER}/${userId}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function createUser(user: IUserDetails) {
  const formData = new FormData();
  formData.append('user', JSON.stringify(user))
  if (user.profilePhoto instanceof File) formData.append("profile_photo", user.profilePhoto);
  return axios({
    method: 'POST',
    url: CREATE_USER,
    data: formData,
  });
}

export function updateUser(user: IUserDetails) {
  const formData = new FormData();
  formData.append('user', JSON.stringify(user))
  if (user.profilePhoto instanceof File) formData.append("profile_photo", user.profilePhoto);
  return axios({
    method: 'POST',
    url: UPDATE_USER,
    data: formData,
  });
}

export function removeUser(userId: number) {
  return axios({
    method: 'DELETE',
    url: REMOVE_USER,
    data: {
      user_id: userId,
    },
  });
}


export function changeUserPassword(userId: number, password: string) {
  return axios({
    method: 'PUT',
    url: `${API_USER}/${userId}/changePassword`,
    data: {
      password,
    },
  });
}

export interface ResponseGetUserList {
  total: number,
  list: IUserDetails[]
}

export function getUserList(options: PaginationOption, cancel: CancelToken) {
  return axios.get<ResponseGetUserList>(GET_USER_LIST, {
    params: {
      limit: options.limit,
      offset: options.offset,
      search: options.search,
      agent: options.filter?.isAgent,
      funder: options.filter?.isFunder,
      id: options.filter?.id,
    },
    cancelToken: cancel
  })
}

export function getToken(id: number, cancel: CancelToken) {
  return axios.get<{token: string}>(`${API_USER}/${id}/token`, {
    cancelToken: cancel
  })
}

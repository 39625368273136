/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { isHexColor } from '../utils/Utils'
import { ResponseWidgetReport1 } from '../pages/dashboard/ReportApi'

interface Props extends React.HTMLAttributes<HTMLElement> {
  className: string
  title: string
  description: string
  textValue?: string
  data?: ResponseWidgetReport1
  chartHeight: string
  chartColor: string
  chartColorLight?: string
}

const WidgetReport1: React.FC<Props> = (props) => {
  const {data, className, title, description, textValue, chartHeight, chartColor, chartColorLight} = props
  const chartRef = useRef<HTMLDivElement | null>(null)
  
  useEffect(() => {
    if (!chartRef.current || !data) {
      return
    }

    const list = data.data.reduce<number[]>((acc, cur) => {
      acc.push(cur.length);
      return acc;
    }, [])

    if (list.length === 1) {
      list.push(list[0], list[0])
      data.category.push(data.category[0], data.category[0])
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(
      data.category, list, chartHeight, chartColor, chartColorLight))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, props])
  const baseColor =  isHexColor(chartColor)?chartColor:getCSSVariableValue('--bs-' + chartColor)
  const lightColor = chartColorLight && isHexColor(chartColorLight)?chartColorLight:getCSSVariableValue('--bs-light-' + chartColor)
  return (
    <div className={`card ${className}`} onClick={props.onClick}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-bold fs-7'>{description}</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div className={`symbol`}>
            <span className={`symbol-label fs-6 fw-bolder min-w-45px w-100 px-2`} style={{backgroundColor: lightColor, color: baseColor}}>
              {textValue}
            </span>
          </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column justify-content-end'>
        {/* begin::Chart */}
        <div ref={chartRef} className='card-rounded-bottom'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (category: string[], data: number[], chartHeight: string, chartColor: string, chartColorLight?: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor =  isHexColor(chartColor)?chartColor:getCSSVariableValue('--bs-' + chartColor)
  const lightColor = chartColorLight && isHexColor(chartColorLight)?chartColorLight:getCSSVariableValue('--bs-light-' + chartColor)
  
  return {
    series: [{
      name: '',
      data,
    }],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: category,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: data.sort((a, b) => b - a)[0]+1,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' สัญญา'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {WidgetReport1}

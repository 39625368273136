/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  description: string
  showIcon?: boolean
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  description,
  showIcon = true,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        
        { showIcon &&
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
        }
        <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>

        <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
      </div>
      {/* end::Body */}
    </a>
  )
}

const StatisticsWidget5v2: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  description,
  showIcon = true,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='symbol symbol-40px symbol-2by3 me-4'>
            { showIcon &&
              <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
            }
          </div>
          <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
            <div className={`text-dark fw-bolder fs-2`}>{title}</div>
            <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </a>
  )
}

export {StatisticsWidget5, StatisticsWidget5v2}

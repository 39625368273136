/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import commafy from 'commafy'
import {PageTitle} from '../../../_metronic/layout/core'
import { StatisticsWidget5v2 } from '../../../_metronic/partials/widgets/statistics/StatisticsWidget5'
import { TotalAmountReport1 } from '../../components/TotalAmountReport1'
import { WidgetReport1 } from '../../components/WidgetReport1'
import { getClosed, getDue, getIncome, getNew, getOverDue, getProfit, getTotalAmount, ResponseIncomeReport, ResponseProfit, ResponseTotalAmount, ResponseWidgetReport1 } from './ReportApi'
import { RevenueReport1 } from '../../components/RevenueReport1'
import { IncomeReport1 } from '../../components/IncomeReport1'
import TableContractPresent, { TableContractPresentType } from '../../components/TableContractPresent'
import { GlobalFilter } from '../../components/GlobalFilter'
import Select from 'react-select'
import { getToken, getUserList, ResponseGetUserList } from '../user/UserApi'
import axios from 'axios'
import { actions } from '../../modules/global-filter'

const DashboardPage: FC = () => {
  const dispatch = useDispatch()
  const auth = useSelector((s: RootState) => s.auth)
  const filter = useSelector((s: RootState) => s.globalFilter);
  const [incomeReport, setIncomeReport] = useState<ResponseIncomeReport>();
  const [profitReport, setProfitReport] = useState<ResponseProfit>({
    category: [],
    interrest: [],
    amount: [],
    created_contract: 0,
    average_percent: 0,
    paid_total: 0,
    payment_total: 0,
  });
  const [newContract, setNewContact] = useState<ResponseWidgetReport1>();
  const [closedContract, setClosedContract] = useState<ResponseWidgetReport1>();
  const [due, setDue] = useState<ResponseWidgetReport1>();
  const [totalAmount, setTotalAmount] = useState<ResponseTotalAmount>({
    total: 0,
    amount: 0,
    interrest: 0,
  });
  const [overdue, setOverDue] = useState<ResponseWidgetReport1>();
  const tableRef = useRef<TableContractPresentType.Ref>(null);
  const [funder, setFunder] = useState<ResponseGetUserList>();

  useEffect(() => {
    console.log(filter);
    getProfit(filter.filter, filter.options)
      .then(({data}) => setProfitReport(data))
      .catch((err) => console.log(err));
    getNew(filter.filter, filter.options)
      .then(({data}) => {
        setNewContact(data);
        tableRef.current?.setTitle('สัญญาใหม่')
        tableRef.current?.setList(data.data.reduce((acc, cur) => acc.concat(cur), []))
      })
      .catch((err) => console.log(err));
    getClosed(filter.filter, filter.options)
      .then(({data}) => setClosedContract(data))
      .catch((err) => console.log(err));
    getDue(filter.filter, filter.options)
      .then(({data}) => setDue(data))
      .catch((err) => console.log(err));
    getOverDue(filter.filter, filter.options)
      .then(({data}) => setOverDue(data))
      .catch((err) => console.log(err));
    getIncome(filter.filter, filter.options)
      .then(({data}) => setIncomeReport(data))
      .catch((err) => console.log(err));
  }, [filter]);

  useEffect(() => {
    getTotalAmount(filter.options)
      .then(({data}) => setTotalAmount(data))
      .catch((err) => console.log(err));
  }, [filter.options])

  useEffect(() => {
    if (funder) return
    getUserList({
      filter: {
        isFunder: true,
      }
    }, axios.CancelToken.source().token).then(({data}) => setFunder(data))
  })

  return <>
    <div className="row">
      {
        auth.user?.isAdmin &&
        <div className="col-12 mb-5">
          <Select
            className="basic-single"
            placeholder="นายทุน..."
            isLoading={funder === undefined}
            isClearable={true}
            isSearchable={true}
            name="fullname"
            options={funder?funder.list.map((l) => ({value: l.id, label: l.fullname})):[]}
            onChange={async (v) => {
              if (v?.value !== undefined) {
                const res = await getToken(v.value, axios.CancelToken.source().token)
                dispatch(actions.setFilter({filter: filter.filter, options: {instead: {
                  id: v.value,
                  token: res.data.token,
                }}}))
              } else {
                dispatch(actions.setFilter({filter: filter.filter, options: {instead: undefined}}))
              }
            }}
            defaultValue={auth.user?.types.isFunder ? {value: auth.user?.id, label: auth.user?.fullname}:undefined}
          />
        </div>
      }
      <div className="col-12 mb-5">
        <div className="card">
          <div className="card-body">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <TotalAmountReport1 className="card" total={totalAmount.total} data={[totalAmount.total - totalAmount.amount, totalAmount.amount]}></TotalAmountReport1>
              </div>
              <div className="col-lg-6 d-flex flex-column justify-content-around">
                <div className="w-100 px-1">
                  <StatisticsWidget5v2
                    className='card-xl-stretch mb-lg-2 border'
                    svgIcon='/media/icons/svg/dollar-sign-solid.svg'
                    color='white'
                    iconColor='danger'
                    title={`${commafy(totalAmount.total.toFixed(2))} บาท`}
                    description='วงเงินกู้'
                    showIcon={true}
                  />
                </div>
                <div className="w-100 px-1">
                  <StatisticsWidget5v2
                    className='card-xl-stretch mb-lg-2 border'
                    svgIcon='/media/icons/svg/coins-solid.svg'
                    color='white'
                    iconColor='dark-secondary'
                    title={`${commafy((totalAmount.amount).toFixed(2))} บาท`}
                    description='ยอดเงินต้นคงเหลือ'
                    showIcon={true}
                  />
                </div>
                <div className="w-100 px-1">
                  <StatisticsWidget5v2
                    className='card-xl-stretch mb-lg-2 border'
                    svgIcon='/media/icons/svg/hand-holding-usd-solid.svg'
                    color='white'
                    iconColor='primary'
                    title={`${commafy((totalAmount.total - totalAmount.amount).toFixed(2))} บาท`}
                    description='ยอดเงินต้นรับมา'
                    showIcon={true}
                  />
                </div>
                <div className="w-100 px-1">
                  <StatisticsWidget5v2
                    className='card-xl-stretch mb-lg-2 border'
                    svgIcon='/media/icons/svg/hand-holding-usd-solid.svg'
                    color='white'
                    iconColor='success'
                    title={`${commafy((totalAmount.interrest).toFixed(2))} บาท`}
                    description='ยอดดอกเบี้ยที่รับมา'
                    showIcon={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mb-5">
        <div className="card">
          <div className="card-header">
            <h3 className='card-title'>รายงานสรุปผล</h3>
            <div className="card-toolbar">
              <GlobalFilter></GlobalFilter>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <RevenueReport1 className="card border h-100" profit={profitReport}></RevenueReport1>
              </div>
              <div className="col-lg-8">
                <div className="border" style={{overflowX: 'scroll'}}>
                  { incomeReport &&
                    <IncomeReport1 data={incomeReport} className="card"></IncomeReport1>
                  }
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="row d-flex h-100">
                  <div className="col-lg-6 mb-1">
                    <WidgetReport1
                      onClick={() => {
                        if (newContract && tableRef.current) {
                          tableRef.current?.setTitle('สัญญาใหม่')
                          tableRef.current.setList(newContract?.data.reduce((acc, cur) => {
                            return acc.concat(cur);
                          }, []))
                        }
                      }}
                      data={newContract}
                      className='card p-0 h-100 border' title='สัญญาใหม่' description='สัญญารายใหม่' textValue={newContract?.data.reduce((acc, cur) => acc += cur.length, 0).toString()} chartColor='#1BC5BD' chartColorLight="#C9F7F5" chartHeight='100px' />
                  </div>
                  <div className="col-lg-6 mb-1">
                    <WidgetReport1
                      onClick={() => {
                        if (closedContract && tableRef.current) {
                          tableRef.current?.setTitle('ปิดสัญญา')
                          tableRef.current.setList(closedContract?.data.reduce((acc, cur) => {
                            return acc.concat(cur);
                          }, []))
                        }
                      }}
                      data={closedContract}
                      className='card p-0 h-100 border' title='ปิดสัญญา' description='รายการสัญญาที่ปิดแล้ว' textValue={closedContract?.data.reduce((acc, cur) => acc += cur.length, 0).toString()} chartColor='success' chartHeight='100px' />
                  </div>
                  <div className="col-lg-6 mt-1">
                    <WidgetReport1
                      onClick={() => {
                        if (overdue && tableRef.current) {
                          tableRef.current?.setTitle('ลูกหนี้เกินชำระ')
                          tableRef.current.setList(overdue?.data.reduce((acc, cur) => {
                            return acc.concat(cur);
                          }, []))
                        }
                      }}
                      data={overdue}
                      textValue={overdue?.data.reduce((acc, cur) => {
                        cur.forEach((c) => {
                          if (acc.find((a) => a.id === c.id) === undefined) {
                            acc.push(c);
                          }
                        })
                        return acc;
                      }, []).length.toString()}
                      className='card p-0 h-100 border' title='ลูกหนี้เกินชำระ' description='จำนวนลูกหนี้ชำระเงินล่าช้าทั้งหมด' chartColor='danger' chartHeight='100px' />
                  </div>
                  <div className="col-lg-6 mt-1">
                    <WidgetReport1
                      onClick={() => {
                        if (due && tableRef.current) {
                          tableRef.current?.setTitle('ค่างวดรอชำระ')
                          tableRef.current.setList(due?.data.reduce((acc, cur) => {
                            return acc.concat(cur);
                          }, []))
                        }
                      }}
                      data={due}
                      className='card p-0 h-100 border' title='ค่างวดรอชำระ' description='รายการค่างวดรอชำระ' textValue={due?.data.reduce((acc, cur) => acc += cur.length, 0).toString()} chartColor='primary' chartHeight='100px' />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <TableContractPresent title="สัญญาใหม่" ref={tableRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>ภาพรวม</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

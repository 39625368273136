import React, {forwardRef, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import commafy from 'commafy';
import { dateFormat, dateTimeFormat } from '../utils/Utils';
import { KTSVG } from '../../_metronic/helpers';

export namespace TableContractPresentType {
  export interface Props {
    title: string,
  } 
  export interface Ref {
    setList: (list: IContractDetails[]) => void
    setTitle: (t: string) => void
  }
}

const TableContractPresent = forwardRef<TableContractPresentType.Ref, TableContractPresentType.Props>((props, ref) => {

  const [data, setData] = useState<{
    list: IContractDetails[];
  }>({list: []})
  const [title, setTitle] = useState('')

  useImperativeHandle(ref, () => ({
    setList: (list) => {
      setData({list})
    },
    setTitle: (t: string) => {
      setTitle(t)
    }
  }));

  const getList = (): Array<IContractDetails> => {
    return data?.list?data.list.reduce<IContractDetails[]>((acc, cur) => {
      if (acc.find((a) => a.id === cur.id) === undefined) {
        acc.push(cur)
      }
      return acc;
    }, []):[];
  }

  function getPaidStatus(c: IContractDetails) {
    if (c.paidStatus === 1) {
      return <><span className='text-danger'>ล่าช้า</span></>
    } else if (c.paidStatus === 2) {
      return <><span className='text-success'>ชำระแล้ว</span></>
    } else if (c.paidStatus === 3) {
      return <>รอชำระ</>
    }
  }

  const getLastestInstallment = (i?: Installment) => {
    if (i) {
      return <>
        {i.order} - {dateFormat(i.paymentDate)}
      </>
    }
    return <></>
  }

  return <>
    <div className="card border mt-2">
      <div className='card-header border-0'>
        <div className='card-title align-items-start flex-column'>
          <b>{title}</b>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="table-responsive">
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='text-nowrap'>#</th>
                <th className='text-nowrap'>ประเภทสัญญา</th>
                <th>ชื่อสัญญา</th>
                <th className='text-end'>ยอดเงินกู้</th>
                <th className='text-center'>สถานะ</th>
                <th className='text-center'>งวดที่ - วันที่ชำระ</th>
                <th className='text-start d-none d-sm-table-cell'></th>
                <th className='text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
                getList().map((c, i) => <tr key={i}>
                <td className=''>{c.orders}</td>
                {/* <td className=''>{c.id}</td> */}
                <td className=''>{c.contractTypeName}</td>
                <td className=''>
                  <div style={{width: '225px'}}>
                    <span className='d-inline-block text-truncate text-dark fw-bolder text-hover-primary fs-6' style={{width: '100%'}}>
                      {c.name}
                    </span>
                    <span className='d-inline-block text-truncate fw-bold text-muted d-block fs-7' style={{width: '100%'}}>
                      <span className='text-dark'>โดย: </span> <Link className="text-muted" to={`/customer/update/${c.customer?.id}`}>{c.customer?.fullname}</Link>
                    </span>
                  </div>
                </td>
                <td className='text-end text-nowrap'>
                  <span className='text-dark fw-bolder text-hover-primary fs-6'>
                    {commafy(c.amount)} บาท
                  </span>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {getPaidStatus(c)}
                  </span>
                </td>
                <td className='text-center'>
                  { c.status === 1 ?
                    <>
                      <span className='badge badge-light-success'>ปกติ</span>
                    </> : <>
                      <span className='badge badge-light-danger'>ปิดบัญชี</span>
                    </>
                  }
                </td>
                <td className='text-center text-nowrap'>
                  {getLastestInstallment(c.installment.find((i) => i.paidDate === null))}
                </td>
                <td className='text-start d-none d-sm-table-cell text-nowrap'>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <span className='text-dark'>สร้างเมื่อ: </span> <span className="text-muted">{dateTimeFormat(c.created)}</span>
                  </span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    <span className='text-dark'>แก้ไขล่าสุด: </span> <span className="text-muted">{dateTimeFormat(c.updated)}</span>
                  </span>
                </td>
                <td className=''>
                  <div className="d-flex">
                    {/* <div onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div className='card shadow-sm'>
                                <div className="card-body">
                                  <h3 className="card-title">เปลี่ยนลำดับสัญญา <b>{c.name}</b>&emsp;</h3>
                                  <form action="" onSubmit={async (e) => {
                                    e.preventDefault()
                                    const orders = Number.parseInt((e.currentTarget.elements.namedItem('orders') as HTMLInputElement).value)
                                    toast.promise(
                                      updateOrders(c, orders, axios.CancelToken.source().token)
                                      .then(() => getContractList({search: searchText, limit, offset, filter, sort}, axios.CancelToken.source().token)), {
                                      pending: 'กำลังปรับลำดับ',
                                      success: 'ปรับลำดับเรียบร้อย',
                                      error: 'เกิดข้อผิดพลาด',
                                    }).then(({data}) => setData(data))
                                    onClose()
                                  }}>
                                    <NumberFormat name="orders"
                                      className='form-control' placeholder="ลำดับ" allowNegative={false} decimalScale={0} />
                                    <div className="d-flex justify-content-end card-toolbar mt-4">
                                      <button type="submit" className="btn btn-active-light-primary">ตกลง</button>
                                      <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                    </div>
                                  </form>
                                </div>
                            </div>
                          );
                        }
                      });
                    }}
                      className='btn btn-icon btn-bg-light btn-color-dark-light btn-sm me-1'>
                      <KTSVG path='/media/icons/svg/sort-solid.svg' />
                    </div> */}
                    <Link to={`/contract/${c.id}`}
                      className='btn btn-icon btn-bg-light btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/General/Search.svg' />
                    </Link>
                    <Link to={`/contract/${c.id}/manage`}
                      className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/Shopping/Money.svg' />
                    </Link>
                    {/* <div onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div className='card shadow-sm'>
                                <div className="card-body">
                                  <h3 className="card-title">ต้องการลบสัญญา&emsp;<b>{c.name}</b>&emsp;ใช่หรือไม่ ?</h3>
                                  <div className="d-flex justify-content-end card-toolbar mt-4">
                                    <span onClick={() => {
                                      toast.promise(deleteContract(c, axios.CancelToken.source().token), {
                                        success: 'ลบสัญญาเรียบร้อย',
                                        error: 'เกิดข้อผิดพลาด',
                                        pending: 'กำลังลบสัญญา'
                                      }).then(() => {
                                        
                                      })
                                      onClose()
                                    }} className="btn btn-active-light-danger">ตกลง</span>
                                    <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                  </div>
                                </div>
                            </div>
                          );
                        }
                      });
                    }}
                      className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'>
                      <KTSVG path='/media/icons/duotone/General/Trash.svg' />
                    </div> */}
                  </div>
                </td>
              </tr>) 
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </div>
  </>
});

export default TableContractPresent;

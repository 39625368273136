/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { deleteCustomer, getContractOfCustomer, getCustomerList } from './CustomerApi'
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import { Modal } from '@material-ui/core';
import { ModalBody, ModalDialog, ModalFooter } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { RootState } from '../../../setup';
import { useSelector } from 'react-redux';
import { getUserList, ResponseGetUserList } from '../user/UserApi';

type Props = {
  className: string
}

const TableCustomer: React.FC<Props> = ({className}) => {
  const auth = useSelector((s: RootState) => s.auth);
  const [loading, setLoading] = useState(true)
  const [deleteId, setDeleteId] = useState<number>()
  const [total, setTotal] = useState<number>()
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<{total: number, list: Array<ICustomerDetails>}>()
  const [funder, setFunder] = useState<ResponseGetUserList>();
  const [funderFilter, setFunderFilter] = useState<number[]>([]);

  useEffect(() => {
    if (funder) return
    getUserList({
      filter: {
        isFunder: true,
      }
    }, axios.CancelToken.source().token).then(({data}) => setFunder(data))
  })

  if (loading) {
    getCustomerList({}, axios.CancelToken.source().token).then(({data: res}) => {
      setData(res);
    }).finally(() => setLoading(false))
  }

  if (loading) return <>loading. . .</>
  
  const getList = (): Array<ICustomerDetails> => {
    if (data?.list) {
      return data?.list
        .filter((u) => {
          if (funderFilter.length > 0) {
            return funderFilter.some((f) => f === u.funder)
          } else {
            return true;
          }
        })
        .filter((u) => {
          if (u.identificationId.search(searchText) !== -1) return true;
          if (u.firstname.search(searchText) !== -1) return true;
          if (u.lastname.search(searchText) !== -1) return true;
          if (u.address.search(searchText) !== -1) return true;
          if (u.phoneNumber.search(searchText) !== -1) return true;
          if (u.lineId.search(searchText) !== -1) return true;
          if (u.email.search(searchText) !== -1) return true;
          if (u.facebook.search(searchText) !== -1) return true;
          return false;
        });
    } else {
      return []
    }
  }

  return (
    <>
      {
      (auth.user?.isAdmin || auth.user?.types.isAgent) &&
        <Select
          className="basic-single my-5"
          placeholder="นายทุน..."
          isLoading={funder === undefined}
          isClearable={true}
          isSearchable={true}
          isMulti={true}
          name="fullname"
          options={funder?funder.list.map((l) => ({value: l.id, label: l.fullname})):[]}
          onChange={async (v) => {
            setFunderFilter(v.map<number>(d => d.value?d.value:0))
          }}
          defaultValue={auth.user?.types.isFunder ? {value: auth.user?.id, label: auth.user?.fullname}:undefined}
        />
      }
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
        
          <div className='card-title align-items-start flex-column'>
            <div className="d-flex align-items-center position-relative my-1">
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                </svg>
              </span>
              <input value={searchText} onChange={(e) => setSearchText(e.target.value)} 
                type="text" className="form-control w-300px ps-15" 
                placeholder="ค้นหา..." />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className=''>#</th>
                  <th className=''>เลขบัตรประชาชน</th>
                  <th className=''>ชื่อลูกค้า</th>
                  <th className='d-none d-sm-table-cell'>ที่อยู่</th>
                  <th className=''>ติดต่อ</th>
                  <th className=' text-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {
                  getList().map((user: ICustomerDetails, i: number) => {
                    return <tr key={i}>
                            <td>{i+1}</td>
                            <td>{user.identificationId}</td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-7 text-truncate'>
                                {user.fullname}
                              </span>
                            </td>
                            <td className='d-none d-sm-table-cell' style={{minWidth: '200px', maxWidth: '200px'}}>
                              <span className='text-dark me-2 fs-7 fw-bold' style={{WebkitLineClamp: 2, textOverflow: 'ellipsis', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical'}}>
                                {user.address}
                              </span>
                            </td>
                            <td>
                              <div className='text-dark me-2 fs-7 fw-bold'>
                                เบอร์โทร: {user.phoneNumber}
                              </div>
                              <div className='text-dark me-2 fs-7 fw-bold'>
                                อีเมล์: {user.email}
                              </div>
                              <div className='text-dark me-2 fs-7 fw-bold'>
                                facebook: {user.facebook}
                              </div>
                              <div className='text-dark me-2 fs-7 fw-bold'>
                                line: {user.lineId}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <Link to={`/customer/update/${user.id}`}
                                  className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'>
                                  <KTSVG
                                    path='/media/icons/duotone/Communication/Write.svg'
                                    className='svg-icon-3'
                                  />
                                </Link>
                                <a onClick={async () => {
                                  if (user.id) {
                                    try {
                                      toast.loading('กำลังตรวจสอบสัญญา')
                                      const {data} = await getContractOfCustomer(user.id, axios.CancelToken.source().token)
                                      toast.dismiss()
                                      setTotal(data.total)
                                      setDeleteId(user.id)
                                    } catch (error) {
                                      toast.dismiss()
                                      toast.error('เกิดปัญหาในการตรวจสอบ')
                                    }
                                  }
                                }}
                                  className='btn btn-icon btn-bg-light btn-color-danger btn-sm me-1'>
                                  <KTSVG
                                    path='/media/icons/duotone/General/Trash.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                              </div>
                            </td>
                          </tr>
                  })
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <Modal open={deleteId != null}>
        <ModalDialog>
          <ModalBody>
            <h3>ยืนยันการลบลูกค้า</h3>
            <div><span className="text-danger">*</span>หมายเหตุ: จะมีสัญญาหายไป <span className="text-danger">{total}</span> สัญญา</div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => {
              if (deleteId) {
                toast.promise(deleteCustomer(deleteId, axios.CancelToken.source().token).then(
                  () => getCustomerList({}, axios.CancelToken.source().token)
                ), {
                  pending: 'กำลังลบลูกค้า',
                  success: 'ลบลูกค้าเรียบร้อย',
                  error: 'เกิดข้อผิดพลาด',
                }).then(({data}) => {
                  setData(data)
                })
                setDeleteId(undefined);
              }
            }}>ลบ</button>
            <button className="btn btn-secondary" onClick={() => setDeleteId(undefined)}>ยกเลิก</button>
          </ModalFooter>
        </ModalDialog>
      </Modal>
    </>
  )
}

export {TableCustomer}

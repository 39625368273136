import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { KTSVG } from '../../../_metronic/helpers';
import { contractType } from '../../constant';
import { calInterrest, calPaidPerMonth } from '../../modules/interrest/interrest';
import { getProfilePhoto } from '../../utils/Utils';
// import { KTSVG } from '../../../_metronic/helpers';

const ContractResult: FC = () => {
  const formik = useFormikContext<IContractDetails>()
  const funder = formik.values.funder;
  const agent = formik.values.agent;
  const amount = formik.values.amount??0.00;
  const percent = formik.values.percent??0;
  const months = formik.values.months??0;
  return <>
    <div className="row w-100">
      <div className="row offset-lg-2 col-lg-8 p-0">
        <div className="col-ls-12">
          <h1>สัญญา - {contractType.find((ct) => (ct.id.toString() === formik.values.contractType))?.name}</h1>
          <div className="separator  my-5"></div>
        </div>
        <div className="col-ls-12">
          <h3>ข้อมูลผู้ทำสัญญา</h3>
          <div className="row">
            <span><strong className='col-lg-12'>เลขบัตรประชาชน: </strong> {formik.values.customer?.identificationId}</span>
            <span><strong className='col-lg-12'>ชื่อ - นามสกุล: </strong> {`${formik.values.customer?.firstname} ${formik.values.customer?.lastname}`}</span>
            <span><strong className='col-lg-12'>เบอร์โทรศัพท์: </strong> {formik.values.customer?.phoneNumber??'-'}</span>
            <span><strong className='col-lg-12'>อีเมล์: </strong> {formik.values.customer?.email??'-'}</span>
            <span><strong className='col-lg-12'>Facebook: </strong> {formik.values.customer?.facebook??'-'}</span>
            <span><strong className='col-lg-12'>Line ID: </strong> {formik.values.customer?.lineId??'-'}</span>
            <span><strong className='col-lg-12'>ที่อยู่: </strong> {formik.values.customer?.address??'-'}</span>
          </div>
        </div>
        <div className="separator border-2 my-5"></div>
        <div className="col-ls-12">
          <h3>ข้อมูลผู้ค้ำ{formik.values.guarantor?'':' - ไม่มี'}</h3>
          { formik.values.guarantor &&
            <div className="row">
              <span><strong className='col-lg-12'>เลขบัตรประชาชน: </strong> {formik.values.guarantor.identificationId}</span>
              <span><strong className='col-lg-12'>ชื่อ - นามสกุล: </strong> {`${formik.values.guarantor.firstname} ${formik.values.guarantor.lastname}`}</span>
              <span><strong className='col-lg-12'>เบอร์โทรศัพท์: </strong> {formik.values.guarantor.phoneNumber??'-'}</span>
              <span><strong className='col-lg-12'>อีเมล์: </strong> {formik.values.guarantor.email??'-'}</span>
              <span><strong className='col-lg-12'>Facebook: </strong> {formik.values.guarantor.facebook??'-'}</span>
              <span><strong className='col-lg-12'>Line ID: </strong> {formik.values.guarantor.lineId??'-'}</span>
              <span><strong className='col-lg-12'>ที่อยู่: </strong> {formik.values.guarantor.address??'-'}</span>
            </div>
          }
        </div>
        <div className="separator border-2 my-5"></div>
        <div className="col-ls-12">
          <h3>รายละเอียดสัญญา</h3>
          <div className="row">

            <div className="col-lg-6 mt-2">
              <label className="form-label">นายทุน</label>
              <div
                className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
                { funder ?
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px border'>
                      <img alt='Pic' src={getProfilePhoto(funder.profilePhoto)} />
                    </div>

                    <div className='ms-5'>
                      <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                        {funder.username}
                      </span>
                      <div className='fw-bold text-dark'>{funder.fullname}</div>
                    </div>
                  </div> : <div>
                    <div className='symbol symbol-45px'>
                      <img alt='Pic' src={getProfilePhoto(null)} />
                    </div>
                    <span className="m-2">กรุณาเลือกนายทุน</span>
                  </div>
                }
                <div className="overlay-layer bg-dark bg-opacity-25">
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mt-2">
              <label className="form-label">นายหน้า</label>
              <div
                className='overlay d-flex flex-stack p-3 border-gray-50 border-dashed'>
                { agent ?
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px border'>
                      <img alt='Pic' src={getProfilePhoto(agent.profilePhoto)} />
                    </div>

                    <div className='ms-5'>
                      <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                        {agent.username}
                      </span>
                      <div className='fw-bold text-dark'>{agent.fullname}</div>
                    </div>
                  </div> : <div>
                    <div className='symbol symbol-45px'>
                      <img alt='Pic' src={getProfilePhoto(null)} />
                    </div>
                    <span className="m-2">กรุณาเลือกนายหน้า</span>
                  </div>
                }
                <div className="overlay-layer bg-dark bg-opacity-25">
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mt-2">
              <label className="form-label">ยอดเงินกู้(บาท)</label>
              <NumberFormat disabled={true} {...formik.getFieldProps('amount')} className="form-control" thousandSeparator={true} min={0} placeholder="0"/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">ดอกเบี้ย/เดือน</label>
              <NumberFormat disabled={true} className="form-control" placeholder="%" suffix="%" {...formik.getFieldProps('percent')}/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">เดือน</label>
              <NumberFormat disabled={true} className="form-control" placeholder="เดือน" {...formik.getFieldProps('months')} />
            </div>
            
            <div className="col-lg-6 mt-2">
              <label className="form-label">วันที่เริ่มจ่าย</label>
              <input disabled={true} className="form-control" value={moment(formik.values.startDate).clone().add(543, 'y').format('DD MMM YYYY')}/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">ดอกเบี้ย/เดือน</label>
              <NumberFormat thousandSeparator={true} value={calInterrest(amount, percent).toFixed(2)} className="form-control" placeholder='0 บาท' disabled={true}/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">รวมดอกเบี้ย</label>
              <NumberFormat thousandSeparator={true}  value={(calInterrest(amount, percent)) * months} className="form-control" placeholder='0 บาท' disabled={true}/>
            </div>

            <div className="col-lg-6 mt-2">
              <label className="form-label">วันที่สร้างสัญญา</label>
              <input disabled={true} className="form-control" value={moment(formik.values.created).clone().add(543, 'y').format('DD MMM YYYY HH:mm')}/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">จ่าย/เดือน</label>
              <NumberFormat thousandSeparator={true}  value={calPaidPerMonth(amount, percent, months)} className="form-control" placeholder='0 บาท' disabled={true}/>
            </div>
            
            <div className="col-lg-3 mt-2">
              <label className="form-label">รวมทั้งหมด</label>
              <NumberFormat thousandSeparator={true}  value={((calInterrest(amount, percent) * months + amount))} className="form-control" placeholder='0 บาท' disabled={true}/>
            </div>
            
            
            <div className="col-lg-12 mt-2">
              <label className="form-label">รายละเอียดเพิ่มเติม</label>
              <textarea disabled={true} {...formik.getFieldProps('description')} className="form-control" rows={3} placeholder="รายละเอียดเพิ่มเติม"/>
            </div>
            
                
            <div className="col-lg-12 mt-5">
              <label className="form-label">เอกสาร</label>
              <div className="w-100 position-relative h-300px overflow-auto border border-gray-300 border-dashed rounded">
                { formik.values.files.length > 0 ?
                  <>
                    <div className="d-flex flex-wrap flex-rows">
                    {
                      formik.values.files.map((f: any, i: number) => {
                        return <React.Fragment key={i}>
                          <div className="col-12 col-sm-6 col-lg-6">
                            <div className="card shadow m-2 d-flex flex-row">
                              <div className="m-2">
                                <KTSVG className="svg-icon-muted svg-icon-3hx" path='/media/icons/duotone/Files/File.svg'/>
                              </div>
                              <div className="mt-2 flex-grow-1 text-truncate">
                                <div style={{display: 'inline-block'}} className="cursor-edit fs-5 fw-bolder">
                                  <div className="d-flex align-items-center">
                                    <span >{f.name}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      })
                    }
                    </div>
                  </> : <>
                    <div className="d-flex position-absolute h-100 w-100 justify-content-center opacity-2 align-items-center">
                      <h3>เอกสาร</h3>
                    </div>
                  </>
                }
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </>
}

export {ContractResult}
import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { TablePrefix } from './TablePrefix';

const PrefixListPage : React.FC = () => {
  return <>
    <PageTitle breadcrumbs={[]}>คำนำหน้า</PageTitle>
    <TablePrefix className="" />
  </>
}

export {PrefixListPage};
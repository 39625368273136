/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { UserCreateForm } from './UserCreateForm'

const UserCreate: FC = () => {
  const intl = useIntl()
  return <>
    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USERS_CREATE'})}</PageTitle>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-4'>
      <div className='offset-xl-1 col-xl-10'>
        <UserCreateForm></UserCreateForm>
      </div>
    </div>
    {/* end::Row */}
  </>
}

export {UserCreate};
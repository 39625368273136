import axios, { CancelToken } from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001';

const API_INSTALLMENT = `${API_URL}/v1/installment`;

export namespace InstallmentApi {
  export function editInterrest(installment: Installment, newInterrest: number, cancel: CancelToken) {
    return axios.put(`${API_INSTALLMENT}/${installment.id}/edit/interrest`, {
      interrest: newInterrest
    }, {
      cancelToken: cancel,
    });
  }

  export function unpaid(installment: Installment, cancel: CancelToken) {
    return axios.put(`${API_INSTALLMENT}/${installment.id}/unpaid`, {
      cancelToken: cancel,
    });
  }
}
import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { createUser } from './UserApi'
import InputMark from 'react-input-mask'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { getPrefixList } from '../prefix/PrefixAPI'
import axios from 'axios'
import { actions } from '../../modules/prefix'

const usernameRegax = /[a-zA-Z0-9]+$/g;
const passwordRegax = /[a-zA-Z0-9!@#$%^&*()\-__+.]+$/g;

const profileDetailsSchema = Yup.object().shape({
  address: Yup.string(),
  phoneNumber: Yup.string(),
  lineId: Yup.string(),
  email: Yup.string().email('email ไม่ถูกต้อง'),
  lastname: Yup.string().required('กรุณากรอกนามสกุล'),
  firstname: Yup.string().required('กรุณากรอกชื่อ'),
  prefix: Yup.string().test('', 'กรุณากรอกเลือกคำนำหน้า', (v) => v !== '-1'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'ยืนยันรหัสผ่านไม่ถูกต้อง').required('กรุณายืนยันรหัสผ่าน'),
  password: Yup.string().required('กรุณากรอกรหัสผ่าน').min(8, 'รหัสผ่านขั้นต่ำ 8 ตัวอักษร').matches(passwordRegax, 'รหัสผ่านมีอักขระไม่ถูกต้อง'),
  username: Yup.string().required('กรุณากรอกชื่อผู้ใช้งาน').min(8, 'ชื่อผู้ใช้งานขั้นต่ำ 8 ตัวอักษร').matches(usernameRegax, 'ชื่อผู้ใช้งานมีอักขระไม่ถูกต้อง').lowercase('ชื่อผู้ใช้งานต้องเป็นพิมพ์เล็ก'),
})

const initialValues: IUserDetails = {
  username: '',
  password: '',
  passwordConfirmation: '',
  prefix: -1,
  prefixName: '',
  firstname: '',
  lastname: '',
  address: '',
  phoneNumber: '',
  lineId: '',
  email: '',
  isAdmin: false,
  types: {
    isFunder: false,
    isAgent: false,
  },
  profilePhoto: null,
}

const UserCreateForm: React.FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const prefix = useSelector((selector: RootState) => selector.prefix)
  const formik = useFormik<IUserDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validate: (v) => {
      profileDetailsSchema.validate(v)
        .then(() => {})
        .catch((e) => toast.error(e.message))
    },
    onSubmit: (values) => {
      console.log(values);
      setLoading(true)
      toast.promise(createUser(values).then(() => 
        getPrefixList(axios.CancelToken.source().token)
      ), {
        success: "บันทึกสำเร็จ",
        error: "เกิดข้อผิดพลาด",
        pending: "กำลังบันทึก",
      })
        .then(({data}) => {
          formik.setValues(initialValues)
          dispatch(actions.setPrefix(data))
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          setLoading(false)
        })
    },
  })
  function getUrlPhoto(photo: any) {
    if (photo instanceof File) {
      return URL.createObjectURL(photo)
    } else if (photo instanceof String) {
      return photo
    } else {
      return toAbsoluteUrl('/media/avatars/blank.png');
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        role='button'
        aria-expanded='true'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>ข้อมูลผู้ใช้งานระบบ</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>รูปโปรไฟล์</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}>
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${getUrlPhoto(formik.values.profilePhoto)})`}}
                  ></div>
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                      </svg>
                      <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
                        formik.setFieldValue('profilePhoto', e.target.files?.item(0))
                      }}/>
                  </label>
                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Remove avatar" onClick={() => formik.setFieldValue('profilePhoto', null)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x fs-2" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ชื่อผู้ใช้งาน</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='ชื่อผู้ใช้งาน'
                  {...formik.getFieldProps('username')}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.username}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>รหัสผ่าน</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg'
                  placeholder='รหัสผ่าน'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ยืนยันรหัสผ่าน</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg'
                  placeholder='ยืนยันรหัสผ่าน'
                  {...formik.getFieldProps('passwordConfirmation')}
                />
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>คำนำหน้าชื่อ</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-lg'
                  {...formik.getFieldProps('prefix')}
                >
                  <option value='-1'>กรุณาเลือกคำนำหน้า..</option>
                  { prefix.map((p, i) => <option key={i} value={p.id}>{p.name}</option>) }
                </select>
                {formik.touched.prefix && formik.errors.prefix && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.prefix}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
              { formik.getFieldProps(`prefix`).value === '0' && <>
                  <div className="col-lg-8 fv-row">
                    <input {...formik.getFieldProps(`prefixName`)} className="form-control" placeholder="คำนำหน้าอื่นๆ"/>
                  </div>
                </>
              }
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ชื่อ - นามสกุล</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg mb-3 mb-lg-0'
                      placeholder='ชื่อ'
                      {...formik.getFieldProps('firstname')}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstname}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='นามสกุล'
                      {...formik.getFieldProps('lastname')}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ที่อยู่</label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg'
                  placeholder='ที่อยู่'
                  {...formik.getFieldProps('address')}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>เบอร์โทร</label>

              <div className='col-lg-8 fv-row'>
              <InputMark
                  mask="999-999-9999"
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='เบอร์โทร'
                  {...formik.getFieldProps('phoneNumber')}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Line ID</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Line ID'
                  {...formik.getFieldProps('lineId')}
                />
                {formik.touched.lineId && formik.errors.lineId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lineId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>E-mail</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ตำแหน่ง</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline me-5'>
                    <input
                      className='form-check-input'
                      name='types[]'
                      type='checkbox'
                      checked={formik.getFieldProps('types.isFunder').value}
                      onChange={(e) => {
                        formik.setFieldValue('types.isFunder', e.target.checked)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>นายทุน</span>
                  </label>

                  <label className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      name='types[]'
                      type='checkbox'
                      checked={formik.getFieldProps('types.isAgent').value}
                      onChange={(e) => {
                        formik.setFieldValue('types.isAgent', e.target.checked)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>นายหน้า</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>ระดับการใช้งาน</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline me-5'>
                    <input
                      className='form-check-input'
                      name='role'
                      type='checkbox'
                      checked={!formik.getFieldProps('isAdmin').value}
                      onChange={() => {
                        formik.setFieldValue('isAdmin', false)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>User</span>
                  </label>

                  <label className='form-check form-check-inline'>
                  <input
                      className='form-check-input'
                      name='role'
                      type='checkbox'
                      checked={formik.getFieldProps('isAdmin').value}
                      onChange={() => {
                        formik.setFieldValue('isAdmin', true)
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>Admin</span>
                  </label>
                </div>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'สร้าง'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  กำลังสร้าง...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {UserCreateForm}

import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import ReactInputMask from 'react-input-mask'
import { createCustomer } from './CustomerApi'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { getPrefixList } from '../prefix/PrefixAPI'
import { actions } from '../../modules/prefix'
import { DisplayUserSelected, DisplayUserSelectedRef } from '../../components/DisplayUserSelected'

const customerDetailsSchema = Yup.object().shape({
  lastname: Yup.string().required('กรุณากรอกนามสกุล'),
  firstname: Yup.string().required('กรุณากรอกชื่อ'),
  prefix: Yup.string().test('', 'กรุณากรอกเลือกคำนำหน้า', (v) => v !== '-1'),
  identificationId: Yup.string().required('กรุณากรอกเลขบัตรประชาชน').test('', 'บัตรประชาชนไม่ถูกต้อง', (v) => v?.split('-').join('').split('_').join('').length === 13),
  funder: Yup.number().moreThan(0, 'กรุณาเลือกนายทุน'),
})

const initialValues: ICustomerDetails = {
  identificationId: '',
  funder: 0,
  prefix: -1,
  prefixName: '',
  firstname: '',
  lastname: '',
  address: '',
  phoneNumber: '',
  facebook: '',
  lineId: '',
  email: '',
}

const CustomerCreateForm: React.FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const auth = useSelector((selector: RootState) => selector.auth)
  const prefix = useSelector((selector: RootState) => selector.prefix)
  const selectUser = useRef<DisplayUserSelectedRef>(null)
  const formik = useFormik<ICustomerDetails>({
    initialValues,
    validationSchema: customerDetailsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    // validate: (v) => {
    //   customerDetailsSchema.validate(v)
    //     .then(() => {})
    //     .catch((e) => toast.error(e.message))
    // },
    onSubmit: async (values) => {
      if (await customerDetailsSchema.validate(values)) {
        setLoading(true)
        toast.promise(createCustomer(values, axios.CancelToken.source().token).then(() => 
          getPrefixList(axios.CancelToken.source().token)
        ), {
          success: "บันทึกเรียบร้อย",
          error: "เกิดข้อผิดพลาด",
          pending: "กำลังบันทึก",
        })
          .then(({data}) => {
            dispatch(actions.setPrefix(data));
            formik.setValues(initialValues)
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            setLoading(false)
          })
      }
    },
  })
  
  if (formik.values.funder === 0 && auth.user?.types.isFunder && auth.user.id) {
    formik.setFieldValue("funder", auth.user.id);
  }

  return <>
    <PageTitle breadcrumbs={[]}>สร้างใหม่</PageTitle>
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>ข้อมูลลูกค้า</h3>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <DisplayUserSelected 
            ref={selectUser}
            onChangeUser={(u) => {
              formik.setFieldValue('funder', u.id)
            }}
            className="col-lg-12 mt-2" 
            disabled={!(auth.user?.types.isAgent || auth.user?.isAdmin)} userId={formik.values.funder}
            ></DisplayUserSelected>
          {formik.touched.funder && formik.errors.funder && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.funder}</div>
            </div>
          )}
          <div className="col-lg-12 mt-2">
            <label className="form-label">เลขบัตรประชาชน</label>
            <ReactInputMask {...formik.getFieldProps(`identificationId`)} mask="9-9999-9999-999-9" className="form-control" placeholder="_-____-____-___-_"/>
            {formik.touched.identificationId && formik.errors.identificationId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.identificationId}</div>
              </div>
            )}
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">คำนำหน้า</label>
            <select
              className='form-select form-select-lg'
              {...formik.getFieldProps(`prefix`)}
            >
              <option value={-1}>กรุณาเลือกคำนำหน้า..</option>
              { prefix.map((p, i) => <option key={i} value={p.id}>{p.name}</option>) }
            </select> 
            { formik.getFieldProps(`prefix`).value === '0' && <>
                <div className="col-lg-4 mt-2 w-100">
                  <input {...formik.getFieldProps(`prefixName`)} className="form-control" placeholder="คำนำหน้าอื่นๆ"/>
                </div>
              </>
            }
            {formik.touched.prefix && formik.errors.prefix && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.prefix}</div>
              </div>
            )}
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">ชื่อ</label>
            <input  {...formik.getFieldProps(`firstname`)} className="form-control" placeholder="ชื่อ"/>
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.firstname}</div>
              </div>
            )}
          </div>
          <div className="col-lg-4 mt-2">
            <label className="form-label">นามสกุล</label>
            <input {...formik.getFieldProps(`lastname`)} className="form-control" placeholder="นามสกุล"/>
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.lastname}</div>
              </div>
            )}
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">เบอร์โทรศัพท์</label>
            <input {...formik.getFieldProps(`phoneNumber`)} className="form-control" placeholder="เบอร์โทรศัพท์"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">อีเมล์</label>
            <input {...formik.getFieldProps(`email`)} className="form-control" placeholder="อีเมล์"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">Facebook</label>
            <input {...formik.getFieldProps(`facebook`)} className="form-control" placeholder="Facebook"/>
          </div>
          <div className="col-lg-6 mt-2">
            <label className="form-label">Line ID</label>
            <input {...formik.getFieldProps(`lineId`)} className="form-control" placeholder="Line ID"/>
          </div>
          <div className="col-lg-12 mt-2">
            <label className="form-label">ที่อยู่</label>
            <textarea {...formik.getFieldProps(`address`)} className="form-control" placeholder="ที่อยู่"/>
          </div>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'สร้าง'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  กำลังสร้าง...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  </>
}

export {CustomerCreateForm}

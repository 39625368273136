import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form} from 'formik'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import { SelectContractType } from './SelectContractType'
import { CustemerDetailForm } from './CustomerDetailForm'
import { ContractForm } from './ContractForm'
import { createContract } from './ContractApi'
import axios from 'axios'
import { ContractResult } from './ContractResult'
import moment from 'moment';
import "moment/locale/th";
import { useHistory } from 'react-router'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPrefixList } from '../prefix/PrefixAPI'
import { useDispatch } from 'react-redux'
import { actions } from '../../modules/prefix'
moment.locale('th');

const inits: IContractDetails = {
  orders: 0,
  contractType: '0',
  name: '',
  customer: {
    identificationId: '',
    funder: 0,
    prefix: -1,
    prefixName: '',
    firstname: '',
    lastname: '',
    address: '',
    phoneNumber: '',
    facebook: '',
    lineId: '',
    email: '',
  },
  // startDate: moment().startOf('date').add(543, 'year'),
  // created: moment().add(543, 'year'),
  startDate: moment().startOf('date'),
  created: moment(),
  agent: undefined,
  funder: undefined,
  guarantor: {
    identificationId: '',
    funder: 0,
    prefix: -1,
    prefixName: '',
    firstname: '',
    lastname: '',
    address: '',
    phoneNumber: '',
    facebook: '',
    lineId: '',
    email: '',
  },
  amount: 0.00,
  total: 0.00,
  months: 0,
  percent: 0,
  files: [],
  description: '',
  installment: [],
}

const createContractSchemas = [
  Yup.object({
    contractType: Yup.string().required().test('', 'กรุณาเลือดประเภทสัญญา', (v) => v !== '0'),
  }),
  Yup.object({
    customer: Yup.object().test('', 'กรุณาเลือกหรือใส่ข้อมูลลูกค้า', (v) => {
      return (v.id !== undefined && v.id !== '')  || (
        (v.identificationId !== undefined && v.identificationId !== '') &&
        (v.firstname !== undefined && v.firstname !== '') &&
        (v.lastname !== undefined && v.lastname !== '')
      )
    }),
  }),
  Yup.object({}),
  Yup.object({
    months: Yup.number().required().moreThan(0, 'กรุณาใส่เดือน'),
    percent: Yup.number().required().moreThan(0, 'ดอกเบี้ยต้องไม่น้อยกว่า 0'),
    amount: Yup.number().required().moreThan(0, 'กรุณาใส่ยอดเงินกู้'),
    funder: Yup.object().required('กรุณาเลือกนายทุน'),
  }),
  Yup.object({}),
]

const ContractWizard: FC = () => {
  const dispatch = useDispatch()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const history = useHistory()
  const [isLast, setIsLast] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(createContractSchemas[0])
  const [initValues] = useState<IContractDetails>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setIsLast(false);
    stepper.current.goPrev()
    setCurrentSchema(createContractSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: IContractDetails, { resetForm }: any) => {
    if (!stepper.current) {
      return;
    }
    setCurrentSchema(createContractSchemas[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex <= 4) {
      stepper.current.goNext()
      if (stepper.current.currentStepIndex > 4) setIsLast(true)
    } else {
      toast.promise(
        createContract(values, axios.CancelToken.source().token),
        {
          pending: 'กำลังสร้างสัญญา',
          success: 'สร้างสัญญาเรียบร้อย',
          error: 'เกิดข้อผิดพลาด'
        }
      ).then(({data}) => {
        resetForm()
        getPrefixList(axios.CancelToken.source().token).then(({data}) => {
          dispatch(actions.setPrefix(data));
        })
        history.push(`/contract/${data.contract_id}`)
      })
      .catch((e) => {
      }).finally(() => {
      });
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])
  
  return (<>
      <div className='card'>
        <div className='card-body'>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column'
          >
            <div className='stepper-nav justify-content-between w-100 mb-5'>
              <div className='stepper-item m-0 mt-4 current' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>1. ประเภทสัญญา</h3>
              </div>

              <div className='stepper-item m-0 mt-4' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>2. ข้อมูลผู้ทำสัญญา</h3>
              </div>

              <div className='stepper-item m-0 mt-4' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>3. ข้อมูลผู้ค้ำประกัน</h3>
              </div>

              <div className='stepper-item m-0 mt-4' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>4. รายละเอียดสัญญา</h3>
              </div>

              <div className='stepper-item m-0 mt-4' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>5. ตรวจสอบ</h3>
              </div>
            </div>

            <Formik
              validationSchema={currentSchema} 
              initialValues={initValues} 
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={submitStep}
              validate={(v) => {
                currentSchema.validate(v)
                  .then(() => {})
                  .catch(e => toast.error(e.message))
              }}>
              {() => (
                <Form
                  className='mx-auto w-100 pt-15 pb-10'
                  noValidate
                >
                  <div data-kt-stepper-element='content' className='current' >
                    <SelectContractType></SelectContractType>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <CustemerDetailForm keyField="customer" title='ข้อมูลลูกค้า'></CustemerDetailForm>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <CustemerDetailForm keyField="guarantor" title='ข้อมูลผู้ค้ำ'></CustemerDetailForm>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <ContractForm />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <ContractResult />
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Left-2.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isLast && 'ต่อไป'}
                          {isLast && 'สร้าง'}
                          <KTSVG
                            path='/media/icons/duotone/Navigation/Right-2.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export {ContractWizard}

function calInterrest(amount?: number, percent?: number) {
  if (!amount) amount = 0;
  if (!percent) percent = 0;
  try {
    return (amount * (percent/100)) 
  } catch (error) {
    return 0
  }
}

function calPaidPerMonth(amount: number, percent: number, months: number) {
  try {
    if (months === 0) months = 1;
    return (amount * (percent/100)) + (amount/months??1)
  } catch (error) {
    console.log('asd', error);
    return 0
  }
}

export {calInterrest, calPaidPerMonth}

import MomentUtils from "@date-io/moment";
import { Moment } from "moment";

export class OverrideDatePickerUtils extends MomentUtils {
  getYearText(date: Moment) {
    return (date.year() + 543).toString();
  }

  toBuddhistYear(moment: Moment, format: string) {
    var christianYear = moment.format('YYYY')
    var buddhishYear = (parseInt(christianYear) + 543).toString()
    return moment
        .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
        .replace(christianYear, buddhishYear)
  }

  format(date: Moment, formatKey: string) {
    return this.toBuddhistYear(date,formatKey);
  };
}

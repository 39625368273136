import axios, { CancelToken } from 'axios'
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
export const GET_CUSTOMER_LIST = `${API_URL}/v1/customer/list`
export const CREATE_CUSTOMER = `${API_URL}/v1/customer/create`
export const API_CUSTOMER = `${API_URL}/v1/customer`

export function getCustomerList(options: PaginationOption, cancel: CancelToken) {
  return axios.get(GET_CUSTOMER_LIST, {
    params: {
      limit: options.limit,
      offset: options.offset,
      search: options.search,
      agent: options.filter?.isAgent,
      funder: options.filter?.isFunder,
    },
    cancelToken: cancel
  })
}

export function getCustomer(id: number, cancel: CancelToken) {
  return axios.get(`${API_CUSTOMER}/${id}`, {
    cancelToken: cancel
  })
}

export function updateCustomer(customer: ICustomerDetails, cancel: CancelToken) {
  return axios.put(`${API_CUSTOMER}/${customer.id}`, {
    customer,
  }, {
    cancelToken: cancel
  })
}

export function createCustomer(customer: ICustomerDetails, cancel: CancelToken) {
  return axios.post(CREATE_CUSTOMER, customer, {cancelToken: cancel});
}

export function deleteCustomer(id: number, cancel: CancelToken) {
  return axios.delete(API_CUSTOMER+`/${id}`, {cancelToken: cancel});
}

export function getContractOfCustomer(id: number, cancel: CancelToken) {
  return axios.get<any>(API_CUSTOMER+`/${id}/contracts`, {cancelToken: cancel});
}
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const DocumentPdf: React.FC = () => {
  const [numPdfPages, setNumPdfPages] = useState<number>()
  return <div className="d-flex justify-content-center w-100">
      <Document
        file={toAbsoluteUrl('/docs.pdf')}
        onLoadSuccess={({numPages}) => {
          setNumPdfPages(numPages)
        }}
        onLoadError={(e) => {
          console.log(e);
        }}
      >
        {
          Array.from(
            new Array(numPdfPages),
            (el, index) => (
              <Page
                className="my-2"
                scale={1.5}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )
        }
      </Document>
  </div>
}

export {DocumentPdf}
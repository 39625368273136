/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { TableCustomer } from './TableCustomer'

const CustomerListPage: FC = () => {
  return <>
    <PageTitle breadcrumbs={[]}>ข้อมูลลูกค้า</PageTitle>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <TableCustomer className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
}

export {CustomerListPage}

import { Modal } from '@material-ui/core';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { RootState } from '../../../setup';
import { KTSVG } from '../../../_metronic/helpers';
import { actions, Prefix } from '../../modules/prefix';
import { createPrefix, getPrefixList, updatePrefix } from './PrefixAPI';

const TablePrefix : React.FC<{className: string}> = ({className}) => {
  const [showModalPrefix, setShowModalPrefix] = useState(false)
  const [editPrefix, setEditPrefix] = useState<Prefix>()
  
  const dispatch = useDispatch()
  const prefixs = useSelector((selector: RootState) => selector.prefix)
  const formik = useFormik<{
    name: string,
  }>({
    initialValues: {
      name: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกคำนำหน้า'),
    }),
    onSubmit: async (v, {validateForm}) => {
      try {
        await validateForm(v)
        setShowModalPrefix(false)
        if (v.name) {
          toast.promise(createPrefix(v.name, axios.CancelToken.source().token)
          .then(() => getPrefixList(axios.CancelToken.source().token)), {
            success: "เพิ่มคำนำหน้าเรียบร้อย",
            error: "เกิดข้อผิดพลาด",
            pending: "กำลังเพิ่มคำนำหน้า",
          }).then(({data}) => {
            formik.resetForm()
            dispatch(actions.setPrefix(data));
          })
        }
      } catch (error) {
      } finally {}
    }
  })

  const formikUpdate = useFormik<Prefix>({
    initialValues: {
      id: editPrefix?.id !== undefined?editPrefix.id:-1,
      name: editPrefix?.name?editPrefix?.name:''
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกคำนำหน้า'),
    }),
    onSubmit: async (v, {validateForm}) => {
      try {
        await validateForm(v)
        if (v.id >= 0) {
          toast.promise(updatePrefix(v, axios.CancelToken.source().token)
          .then(() => getPrefixList(axios.CancelToken.source().token)), {
            success: "เพิ่มคำนำหน้าเรียบร้อย",
            error: "เกิดข้อผิดพลาด",
            pending: "กำลังเพิ่มคำนำหน้า",
          }).then(({data}) => {
            formikUpdate.resetForm()
            dispatch(actions.setPrefix(data));
          })
          setEditPrefix(undefined);
        }
      } catch (error) {
      } finally {}
    }
  })

  return <>
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          <div className="d-flex align-items-center position-relative my-1">
            {/* <span className="svg-icon svg-icon-1 position-absolute ms-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
              </svg>
            </span> */}
            {/* <input value={searchText} onChange={(e) => setSearchText(e.target.value)} 
              type="text" className="form-control w-300px ps-15" 
              placeholder="ค้นหา..." /> */}
          </div>
        </div>

        <div className='card-toolbar'>
        <button
            className='btn btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => setShowModalPrefix(true)}
          >
            เพิ่ม
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className=''>#</th>
                <th className=''>คำนำหน้า</th>
                <th className=''></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                prefixs.map((p, i) => {
                  return <tr key={i}>
                    <td>{p.id}</td>
                    <td>{p.name}</td>
                    <td>
                      <span onClick={() => {setEditPrefix(p)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTSVG
                          path='/media/icons/duotone/Communication/Write.svg'
                          className='svg-icon-3'
                        />
                      </span>
                    </td>
                  </tr>
                })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <Modal open={showModalPrefix}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">เพิ่มคำนำหน้า</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="">
                <label className="form-label">คำนำหน้า</label>
                <input
                  {...formik.getFieldProps('name')}
                  type="text"
                  className="form-control"
                  placeholder="คำนำหน้า"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={() => formik.submitForm()}>
              ตกลง
            </button>
            <button className="btn btn-secondary" type="button" onClick={() => setShowModalPrefix(false)}>
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal open={editPrefix !== undefined}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">แก้ไขคำนำหน้า</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="">
                <label className="form-label">คำนำหน้า</label>
                <input
                  {...formikUpdate.getFieldProps('name')}
                  type="text"
                  className="form-control"
                  placeholder="คำนำหน้า"
                />
                {formikUpdate.touched.name && formikUpdate.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formikUpdate.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={() => formikUpdate.submitForm()}>
              ตกลง
            </button>
            <button className="btn btn-secondary" type="button" onClick={() => setEditPrefix(undefined)}>
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </>
}

export {TablePrefix};
import {Action} from '@reduxjs/toolkit'

export interface ActionWithPayload<T> extends Action {
  payload: T
}

export const actionTypes = {
  SET_PREFIX: 'SET_PREFIX',
}

const initialState: Prefix[] = []

export interface Prefix {
  id: number,
  name: string,
}

export const reducer = (state: Prefix[] = initialState, action: ActionWithPayload<Prefix[]>): Prefix[] => {
  switch (action.type) {
    case actionTypes.SET_PREFIX: {
      return action.payload
    }
    default:
      return state
  }
}

export const actions = {
  setPrefix: (prefix: Prefix[]) => ({type: actionTypes.SET_PREFIX, payload: prefix}),
}

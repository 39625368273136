import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { getProfilePhoto } from '../../utils/Utils'
import { changeUserPassword, getUserList, removeUser } from './UserApi'
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios'
import { Modal } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify';

type Props = {
  className: string
}

function getTextTypes(user: IUserDetails) {
  const arr = []
  if (user.types.isAgent) arr.push('นายหน้า')
  if (user.types.isFunder) arr.push('นายทุน')
  return arr.join(', ');
}

const TableUser: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(true)
  const [removing, setRemoving] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [changePassword, setChangePassword] = useState<number>();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      password: Yup.string().required('กรุณาใส่รหัสผ่าน').min(6,  'ขั้นต่ำ 6 คัวอักษร'),
      confirmPassword: Yup.string().required('กรุณายืนยันรหัสผ่าน').oneOf([Yup.ref('password'), null], 'ยืนยันรหัสผ่านไม่ถูกต้อง').min(6,  'ขั้นต่ำ 6 คัวอักษร'),
    }),
    onSubmit: async (v, {validateForm}) => {
      try {
        await validateForm(v)
        if (changePassword) {
          toast.promise(
            changeUserPassword(changePassword, v.password),
            {
              pending: 'กำลังเปลี่ยนรหัสผ่าน',
              success: 'เปลี่ยนรหัสผ่านเรียบร้อย',
              error: 'เกิดข้อผิดพลาด'
            }
          )
          setChangePassword(undefined)
        }
      } catch (error) {
      } finally {}
    }
  })
  
  const [filter, setFilter] = useState({
    isAgent: false,
    isFunder: false,
    isAdmin: false,
  })
  const [data, setData] = useState<{total: number, list: Array<IUserDetails>}>()
  if (loading) {
    getUserList({}, axios.CancelToken.source().token).then(({data: res}) => {
      setData(res);
    }).finally(() => setLoading(false))
  }

  if (loading) return <>loading. . .</>
  
  const getList = (): Array<IUserDetails> => {
    if (data?.list) {
      return data?.list.filter((u) => {
        let allTrue = true;
        if (filter.isAdmin && !u.isAdmin) allTrue = false
        if (filter.isAgent && !u.types.isAgent) allTrue = false
        if (filter.isFunder && !u.types.isFunder) allTrue = false
        return allTrue; 
      }).filter((u) => {
        if (u.username.search(searchText) !== -1) return true;
        if (u.firstname.search(searchText) !== -1) return true;
        if (u.lastname.search(searchText) !== -1) return true;
        if (u.address.search(searchText) !== -1) return true;
        if (u.phoneNumber.search(searchText) !== -1) return true;
        if (u.lineId.search(searchText) !== -1) return true;
        if (u.email.search(searchText) !== -1) return true;
        return false;
      });
    } else {
      return []
    }
  }
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          <div className="d-flex align-items-center position-relative my-1">
            <span className="svg-icon svg-icon-1 position-absolute ms-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
              </svg>
            </span>
            <input value={searchText} onChange={(e) => setSearchText(e.target.value)} 
              type="text" className="form-control w-300px ps-15" 
              placeholder="ค้นหา..." />
          </div>
        </div>

        <div
          className='card-toolbar'>
          <button
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
              className='svg-icon-2'
            />
          </button>
          
          <div id="filter" className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>คัดกรอง</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>

              <div className='mb-10'>
                <label className='form-label fw-bold'>ตำแหน่ง:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input checked={filter.isFunder} className='form-check-input' type='checkbox' value=''
                      onChange={(e) => setFilter({...filter, isFunder: e.target.checked})}/>
                    <span className='form-check-label'>นายทุน</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input checked={filter.isAgent}  className='form-check-input' type='checkbox' value=''
                      onChange={(e) => setFilter({...filter, isAgent: e.target.checked})}/>
                    <span className='form-check-label'>นายหน้า</span>
                  </label>
                </div>
              </div>

              <div className='mb-10'>
                <label className='form-label fw-bold'>ระดับการใช้งาน:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input checked={filter.isAdmin} className='form-check-input' type='checkbox' value=''
                      onChange={(e) => setFilter({...filter, isAdmin: e.target.checked})}/>
                    <span className='form-check-label'>Admin</span>
                  </label>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-white btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                  onClick={() => setFilter({
                    isAdmin: false,
                    isAgent: false,
                    isFunder: false
                  })}
                >
                  คืนค่า
                </button>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className=''>#</th>
                <th className=''>ชื่อผู้ใช้งาน</th>
                <th className=''>ตำแหน่ง</th>
                <th className=''>ที่อยู่</th>
                <th className=''>ติดต่อ</th>
                <th className=' text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                getList().map((user: IUserDetails, i: number) => {
                  return <tr key={i}>
                          <td>{i+1}</td>
                          <td className="">
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img src={getProfilePhoto(user.profilePhoto)} alt='' />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-truncate text-dark fw-bolder text-hover-primary fs-6'>
                                  {user.username}
                                </span>
                                <span className='text-truncate text-dark fw-bold d-block fs-7'>
                                  {user.prefixName}{user.firstname} {user.lastname}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className='text-dark fw-bold d-block fs-7 text-truncate'>
                              {
                                getTextTypes(user)
                              }
                            </span>
                          </td>
                          <td style={{minWidth: '200px', maxWidth: '200px'}}>
                            <span className='text-dark me-2 fs-7 fw-bold' style={{WebkitLineClamp: 2, textOverflow: 'ellipsis', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical'}}>
                              {user.address}
                            </span>
                          </td>
                          <td>
                            <div className='text-dark me-2 fs-7 fw-bold'>
                              เบอร์โทร: {user.phoneNumber}
                            </div>
                            <div className='text-dark me-2 fs-7 fw-bold'>
                              อีเมล์: {user.email}
                            </div>
                            <div className='text-dark me-2 fs-7 fw-bold'>
                              line: {user.lineId}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <span onClick={() => setChangePassword(user.id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotone/Home/Key.svg'
                                  className='svg-icon-3'
                                />
                              </span>
                              <Link to={`/user/update/${user.id}`}
                                className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotone/Communication/Write.svg'
                                  className='svg-icon-3'
                                />
                              </Link>
                              <span
                                onClick={() => {
                                  confirmAlert({
                                    customUI: ({ onClose }) => {
                                      return (
                                        <div className='card shadow-sm'>
                                          { !removing ? 
                                            <div className="card-body">
                                              <h3 className="card-title">ต้องการลบผู้ใช้งาน&emsp;<b>{user.username}</b>&emsp;ใช่หรือไม่ ?</h3>
                                              <div className="d-flex justify-content-end card-toolbar mt-4">
                                                <span onClick={() => {
                                                  if(user.id) removeUser(user.id)
                                                    .then(() => {
                                                      setLoading(true);
                                                      onClose()
                                                    })
                                                    .finally(() => {
                                                      onClose()
                                                      setRemoving(false)
                                                    })
                                                }} className="btn btn-active-light-danger">ตกลง</span>
                                                <span onClick={onClose} className="btn btn-active-light-dark">ยกเลิก</span>
                                              </div>
                                            </div>
                                            :
                                            <div className="card-body">
                                              <span className='indicator-progress' style={{display: 'block'}}>
                                                กำลังลบ...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                              </span>
                                            </div>
                                          }
                                        </div>
                                      );
                                    }
                                  });
                                }}
                                className='btn btn-icon btn-bg-light btn-color-danger btn-sm'
                              >
                                <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
                              </span>
                            </div>
                          </td>
                        </tr>
                })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <Modal open={changePassword !== undefined}>
      <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">เปลี่ยนรหัสผ่าน {data?.list.find(u => u.id === changePassword)?.username}</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-10">
                  <label className="form-label">รหัสผ่านใหม่</label>
                  <input
                    {...formik.getFieldProps('password')}
                    type="password"
                    className="form-control"
                    placeholder="รหัสผ่านใหม่"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
                <div className="">
                  <label className="form-label">ยืนยันรหัสผ่านใหม่</label>
                  <input
                    {...formik.getFieldProps('confirmPassword')}
                    type="password"
                    className="form-control"
                    placeholder="ยืนยันรหัสผ่านใหม่"
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => formik.submitForm()}>
                ตกลง
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => setChangePassword(undefined)}>
                ยกเลิก
              </button>
            </div>
          </div>
      </div>
      </Modal>
    </div>
  )
}

export {TableUser}

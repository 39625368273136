/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function AsideMenuMain() {
  const auth = useSelector((s: RootState) => s.auth);
  return (
    <>
      <AsideMenuItemWithSub
        to='/dashboard'
        icon='/media/myicon/dashboard-icon.svg'
        title='รายงาน'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/dashboard' title='ภาพรวม' hasBullet={true} />
        <AsideMenuItem to='/report/income' title='รายงานการรับเงิน' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>สัญญาฝากขาย จำนอง</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/contract'
        title='สัญญา'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Files/File.svg'
      >  
        <AsideMenuItem to='/contract/list/open' title='ข้อมูลสัญญา' hasBullet={true} />
        <AsideMenuItem to='/contract/list/close' title='สัญญาที่ปิดแล้ว' hasBullet={true} />
        <AsideMenuItem to='/contract/create' title='สร้างใหม่' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/customer'
        title='ลูกค้า'
        fontIcon='bi-archive'
        icon='/media/icons/svg/user-group-solid.svg'
      >  
        <AsideMenuItem to='/customer/list' title='ข้อมูลลูกค้า' hasBullet={true} />
        {
          (auth.user?.isAdmin || auth.user?.types.isFunder) &&
            <AsideMenuItem to='/customer/create' title='สร้างใหม่' hasBullet={true} />   
        }
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            ตั้งค่าระบบ
          </span>
        </div>
      </div>
      {
        auth.user?.isAdmin &&
        <AsideMenuItemWithSub
          to='/user'
          title='ผู้ดูแล'
          fontIcon='bi-archive'
          icon='/media/icons/duotone/Interface/User.svg'
        >  
          <AsideMenuItem to='/user/list' title='ข้อมูลผู้ดูแล' hasBullet={true} />
          <AsideMenuItem to='/user/create' title='สร้างใหม่' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      <AsideMenuItemWithSub
        to='/'
        title='ตั้งค่า'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Code/Settings4.svg'
      >  
        <AsideMenuItem to='/prefix/list' title='คำนำหน้า' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/'
        title='เกี่ยวกับระบบ'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Code/Info-circle.svg'
      >  
        <AsideMenuItem to='/document' title='คู่มือระบบ' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/'
        title='รายงาน'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/report1' title='สัญญาใหม่' hasBullet={true} />
        <AsideMenuItem to='/report2' title='การชำระค่างวด' hasBullet={true} />
        <AsideMenuItem to='/report3' title='ลูกหนี้คงเหลือ' hasBullet={true} />
        <AsideMenuItem to='/report4' title='ลูกหนี้เกินชำระ' hasBullet={true} />
        <AsideMenuItem to='/report5' title='ปิดสัญญา' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </>
  )
}

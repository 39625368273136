import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { ContractWizard } from "./ContractWizard";

const CreateContractPage: FC = () => {
  const intl = useIntl()
  return <>
    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CONTRACTS_CREATE'})}</PageTitle>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-4'>
      <div className='col-xl-12'>
        <ContractWizard></ContractWizard>
      </div>
    </div>
  </>
}

export {CreateContractPage};

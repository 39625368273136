import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { getUserList, ResponseGetUserList } from '../user/UserApi';
import Select from 'react-select';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OverrideDatePickerUtils } from '../../utils/OverrideDatePickerUtils';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getReceive, ResponseIncomeReport } from '../dashboard/ReportApi';
import { IncomeReport1 } from '../../components/IncomeReport1';
import commafy from 'commafy';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const ReportPage = () => {
  const auth = useSelector((s: RootState) => s.auth)
  const [funder, setFunder] = useState<ResponseGetUserList>()
  const [agent, setAgent] = useState<ResponseGetUserList>()
  const [from, setFrom] = useState(moment().subtract(11, 'M').startOf('M'))
  const [to, setTo] = useState(moment().endOf('M'))
  const [options, setOptions] = useState<{
    funder?: number,
    agent?: number,
  }>({
    funder: auth.user?.id && auth.user?.types.isFunder ? auth.user.id : undefined,
    agent: auth.user?.id && auth.user?.types.isAgent && !auth.user?.types.isFunder ? auth.user.id : undefined,
  })
  const [receiveReport, setReceiReport] = useState<ResponseIncomeReport>();
  const getData = () => {
    getReceive(
      from.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
      to.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
      options)
      .then(({data}) => {
        setReceiReport(data);
      })
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (agent) return
    getUserList({
      filter: {
        isAgent: true,
      }
    }, axios.CancelToken.source().token).then(({data}) => setAgent(data))
  })

  useEffect(() => {
    if (funder) return
    getUserList({
      filter: {
        isFunder: true,
      }
    }, axios.CancelToken.source().token).then(({data}) => setFunder(data))
  })

  return <>
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              รายงานการรับเงิน
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="card border">
                  <div className="offset-lg-1 col-lg-10 mb-2">
                    { receiveReport &&
                      <IncomeReport1 data={receiveReport} chartheight='400px' className=""></IncomeReport1>
                    }
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <label>นายทุน</label>
                <Select
                  className="basic-single"
                  placeholder="นายทุน..."
                  isLoading={funder === undefined}
                  isClearable={true}
                  isSearchable={true}
                  isDisabled={!auth.user?.types.isAgent}
                  name="fullname"
                  options={funder?funder.list.map((l) => ({value: l.id, label: l.fullname})):[]}
                  onChange={(v) => {
                    setOptions({...options, ...{funder: v?.value}})
                  }}
                  defaultValue={auth.user?.types.isFunder ? {value: auth.user?.id, label: auth.user?.fullname}:undefined}
                />
              </div>
              <div className="col-lg-6 mb-2">
                <label>นายหน้า</label>
                <Select
                  className="basic-single"
                  placeholder="นายหน้า..."
                  isLoading={agent === undefined}
                  isClearable={true}
                  isSearchable={true}
                  isDisabled={!auth.user?.types.isFunder}
                  name="fullname"
                  options={agent?agent.list.map((l) => ({value: l.id, label: l.fullname})):[]}
                  onChange={(v) => {
                    setOptions({...options, ...{agent: v?.value}})
                  }}
                  defaultValue={auth.user?.types.isAgent && !auth.user?.types.isFunder ? {value: auth.user?.id, label: auth.user?.fullname}:undefined}
                />
              </div>
              <div className="col-lg-6 mb-2">
                <label>จาก</label>
                <DatePicker
                    InputProps={{ disableUnderline: true }}
                    style={{padding: '0.5rem 0.75rem', border: '1px solid #E4E6EF'}}
                    views={["year", "month"]}
                    maxDate={'3000-01-01'}
                    autoOk={true}
                    variant="inline"
                    format="MMMM YYYY"
                    className="form-control"
                    value={from}
                    onChange={(d: MaterialUiPickersDate) => {
                      if (d) setFrom(d.startOf('M'));
                    }}
                  />
              </div>
              <div className="col-lg-6 mb-2">
                <label>ถึง</label>
                <DatePicker
                    InputProps={{ disableUnderline: true }}
                    style={{padding: '0.5rem 0.75rem', border: '1px solid #E4E6EF'}}
                    views={["year", "month"]}
                    minDate={from}
                    maxDate={'3000-01-01'}
                    autoOk={true}
                    variant="inline"
                    format="MMMM YYYY"
                    className="form-control"
                    value={to}
                    onChange={(d: MaterialUiPickersDate) => {
                      if (d) setTo(d.endOf('M'));
                    }}
                  />
              </div>
              <div className="col-lg-12 d-flex justify-content-end mb-5">
                <button className="btn btn-sm btn-primary" onClick={() => getData()}>ค้นหา</button>
              </div>
              <div className="col-lg-12">
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gs-0 gy-4 border'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='border fw-bolder'>
                        <th className='border text-center align-middle'>เดือนปี</th>
                        <th className='border text-center'>ยอดเงินต้น</th>
                        <th className='border text-center'>ยอดดอกเบี้ย</th>
                        <th className='border text-center'>ยอดรวม</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        receiveReport?.data.map((d, i) => <React.Fragment key={i}>
                          <tr>
                            <td className="border text-center">{receiveReport.date[i]}</td>
                            <td className="border text-end">{commafy(d[0])}</td>
                            <td className="border text-end">{commafy(d[1])}</td>
                            <td className="border text-end" style={{paddingRight: '4px'}}>{commafy(d[0] + d[1])}</td>
                          </tr>
                        </React.Fragment>)
                      }
                      <tr style={{borderTop: 'solid', borderTopColor: '#000000'}}>
                        <td className="text-end">รวม</td>
                        <td className="text-end">{commafy(receiveReport?.data.reduce((acc, cur) => acc += cur[0] ,0))}</td>
                        <td className="text-end">{commafy(receiveReport?.data.reduce((acc, cur) => acc += cur[1] ,0))}</td>
                        <td className="text-end" style={{paddingRight: '4px'}}>{commafy(receiveReport?.data.reduce((acc, cur) => acc += (cur[0] + cur[1]) ,0))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

const ReportWrapper = () => {
  return <>
    <PageTitle breadcrumbs={[]}>รายงานการรับเงิน</PageTitle>
    <MuiPickersUtilsProvider libInstance={moment} utils={OverrideDatePickerUtils} locale={'th'} >
      <ReportPage />
    </MuiPickersUtilsProvider>
  </>
}

export {ReportWrapper}
import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as DashboardFilter from '../../app/modules/global-filter'
import * as Prefix from '../../app/modules/prefix'
import * as ContractFilter from '../../app/modules/contract-filter'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  globalFilter: DashboardFilter.reducer,
  prefix: Prefix.reducer,
  contractFilter: ContractFilter.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}

import axios, { CancelToken } from 'axios'
import { Prefix } from '../../modules/prefix'
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
export const API_USER = `${API_URL}/v1/prefix`

export function getPrefixList(cancelToken: CancelToken) {
  return axios.get<Prefix[]>(`${API_USER}/list`, {
    headers: {
      'Content-Type': 'application/json'
    },
    cancelToken,
  })
}

export function createPrefix(name: string, cancelToken: CancelToken) {
  return axios.post(`${API_USER}/`, {
    name,
  }, {
    headers: {
      'Content-Type': 'application/json'
    },
    cancelToken,
  })
}

export function updatePrefix(prefix: Prefix, cancelToken: CancelToken) {
  return axios.put(`${API_USER}/`, prefix, {
    headers: {
      'Content-Type': 'application/json'
    },
    cancelToken,
  })
}


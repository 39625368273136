import axios from 'axios';
import { useFormik } from 'formik';
import React, {FC, useEffect, useState} from 'react';
import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from '../../../setup';
import { PageTitle } from '../../../_metronic/layout/core';
import { DisplayUserSelected } from '../../components/DisplayUserSelected';
import { getCustomer, updateCustomer } from './CustomerApi';

const init: ICustomerDetails = {
  identificationId: '',
  funder: 0,
  prefix: -1,
  firstname: '',
  lastname: '',
  address: '',
  phoneNumber: '',
  facebook: '',
  lineId: '',
  email: '',
}

const CustomerUpdate: FC = () => {
  const {id} = useParams<{id: string}>()
  const [loading, setLoading] = useState(false);
  const auth = useSelector((selector: RootState) => selector.auth)
  const prefix = useSelector((selector: RootState) => selector.prefix)
  const formik = useFormik<ICustomerDetails>({
    enableReinitialize: true,
    initialValues: init,
    onSubmit: (values) => {
      if (values.id !== undefined) {
        setLoading(true);
        values.identificationId = values.identificationId.split('-').join('');
        toast.promise(updateCustomer(values, axios.CancelToken.source().token), {
          success: "บันทึกเรียบร้อย",
          error: "เกิดข้อผิดพลาด",
          pending: "กำลังบันทึก",
        }).then(() => {
        }).finally(() => setLoading(false))
      }
    },
  })
  useEffect(() => {
    if (formik.values.id === undefined) {
      getCustomer(Number.parseInt(id), axios.CancelToken.source().token).then(({data}) => {
        formik.setValues(data);
      })
    }
  }, [formik, formik.values, id])
  return <>
    <PageTitle breadcrumbs={[]}>แก้ไขข้อมูลลูกค้า</PageTitle>
    <div className="card">
      <div className="card-header"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="row">

          <DisplayUserSelected
              onChangeUser={(u) => {
                formik.setFieldValue('funder', u.id)
              }}
              className="col-lg-12 mt-2" 
              disabled={!(auth.user?.types.isAgent || auth.user?.isAdmin)} userId={formik.values.funder}
              ></DisplayUserSelected>
            {formik.touched.funder && formik.errors.funder && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.funder}</div>
              </div>
            )}

            <div className="col-lg-12 mt-2">
              <label className="form-label">เลขบัตรประชาชน</label>
              <ReactInputMask {...formik.getFieldProps(`identificationId`)} mask="9-9999-9999-999-9" className="form-control" placeholder="_-____-____-___-_"/>
            </div>
            <div className="col-lg-4 mt-2">
              <label className="form-label">คำนำหน้า</label>
              <select
                className='form-select form-select-lg'
                {...formik.getFieldProps(`prefix`)}
              >
                <option value={-1}>กรุณาเลือกคำนำหน้า..</option>
                { prefix.map((p, i) => <option key={i} value={p.id}>{p.name}</option>) }
              </select>
            </div>
            <div className="col-lg-4 mt-2">
              <label className="form-label">ชื่อ</label>
              <input  {...formik.getFieldProps(`firstname`)} className="form-control" placeholder="ชื่อ"/>
            </div>
            <div className="col-lg-4 mt-2">
              <label className="form-label">นามสกุล</label>
              <input  {...formik.getFieldProps(`lastname`)} className="form-control" placeholder="นามสกุล"/>
            </div>
            <div className="col-lg-6 mt-2">
              <label className="form-label">เบอร์โทรศัพท์</label>
              <input  {...formik.getFieldProps(`phoneNumber`)} className="form-control" placeholder="เบอร์โทรศัพท์"/>
            </div>
            <div className="col-lg-6 mt-2">
              <label className="form-label">อีเมล์</label>
              <input  {...formik.getFieldProps(`email`)} className="form-control" placeholder="อีเมล์"/>
            </div>
            <div className="col-lg-6 mt-2">
              <label className="form-label">Facebook</label>
              <input  {...formik.getFieldProps(`facebook`)} className="form-control" placeholder="Facebook"/>
            </div>
            <div className="col-lg-6 mt-2">
              <label className="form-label">Line ID</label>
              <input  {...formik.getFieldProps(`lineId`)} className="form-control" placeholder="Line ID"/>
            </div>
            <div className="col-lg-12 mt-2">
              <label className="form-label">ที่อยู่</label>
              <textarea {...formik.getFieldProps(`address`)} className="form-control" placeholder="ที่อยู่"/>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && 'บันทึก'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              กำลังบันทึก...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      </form>
    </div>
  </>
}

export {CustomerUpdate}
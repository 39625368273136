import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { isHexColor } from '../utils/Utils'
import commafy from 'commafy';

export interface TotalAmountReport1Props extends React.HTMLAttributes<HTMLElement> {
  data: number[]
  total: number,
}
const rColor = 'primary';
const aColor = 'secondary';
const TotalAmountReport1: React.FC<TotalAmountReport1Props> = (props) => {
  const receiveColor =  isHexColor(rColor)?rColor:getCSSVariableValue('--bs-' + rColor)
  const amountColor =  isHexColor(aColor)?aColor:getCSSVariableValue('--bs-' + aColor)
  const total = props.total;
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartRef) {
      return;
    }

    const chartState: ApexOptions = {
      chart: {
        type: 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      labels: ['ยอดเงินต้นรับมา', 'ยอดเงินต้นคงเหลือ'],
      series: props.data,
      colors: [receiveColor, amountColor],
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (v) => {
            return `${commafy(v)} บาท`;
          }
        }
      },
      states:{
        hover: {
          filter: {
            type: 'darken',
            value: 0.5,
          }
        } 
      },
      plotOptions: {
        pie: {
          donut: {
            size: '55%',
            labels: {
              show: true,
              name: {
                color: '#000',
                formatter: (v) => {
                  return `${commafy(v)}`;
                } 
              },
              value: {
                color: '#000',
                formatter: (v) => {
                  return `${commafy(v)}`;
                } 
              },
              total: {
                show: true,
                label: 'ทั้งหมด',
                color: '#000',
                fontWeight: 1000,
                formatter: (v) => {
                  return `${commafy(total)}`;
                } 
              }
            }
          }
        }
      },
      legend: {
        show: true,
        position: 'bottom',
      }
    };

    const chart = new ApexCharts(chartRef.current, chartState);

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  });

  return <>
    <div {...props}>
      <div ref={chartRef}></div>
    </div>
  </>
}

export {TotalAmountReport1}
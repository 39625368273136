/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import {PageTitle} from '../../../_metronic/layout/core'
import { UserUpdateForm } from './UserUpdateForm'

const UserUpdate: FC = () => {
  const intl = useIntl()
  const auth = useSelector((s: RootState) => s.auth)
  return <>
    { auth.user?.isAdmin ? <>
      <PageTitle breadcrumbs={[{
        title: 'ช้อมูลผู้ดูแล',
        path: '/user/list',
        isActive: true,
      }]}>{intl.formatMessage({id: 'MENU.USERS_UPDATE'})}</PageTitle>
    </>:<>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USERS_UPDATE'})}</PageTitle>
    </>}
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-4'>
      <div className='offset-xl-1 col-xl-10'>
        <UserUpdateForm></UserUpdateForm>
      </div>
    </div>
    {/* end::Row */}
  </>
}

export {UserUpdate};
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/user/get-user`
export const LOGIN_URL = `${API_URL}/v1/user/sign-in`
export const CREATE_USER = `${API_URL}/v1/user/create`

export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {username, password}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Server should return AuthModel
// export function register(email: string, firstname: string, lastname: string, password: string) {
//   return axios.post<AuthModel>(REGISTER_URL, {
//     email,
//     firstname,
//     lastname,
//     password,
//   })
// }

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}

import {Action} from '@reduxjs/toolkit'

export interface ActionWithPayload<T> extends Action {
  payload: T
}

export const actionTypes = {
  SET_FILTER: 'SET_FILTER',
}

const initialState: GlobalFilter = {
  filter: 'week',
}

export interface GlobalFilter {
  filter: 'week' | 'month' | 'year' | 'total',
  options?: any,
  paidStatus?: '1' | '2' | '3',
  status?: '1' | '2'
}

export const reducer = (state: GlobalFilter = initialState, action: ActionWithPayload<GlobalFilter>): GlobalFilter => {
  switch (action.type) {
    case actionTypes.SET_FILTER: {
      const filter = action.payload?.filter
      const options = {...state.options, ...action.payload?.options}
      const paidStatus = action.payload?.paidStatus
      const status = action.payload?.status
      return {...state, filter, paidStatus, status, options}
    }
    default:
      return state
  }
}

export const actions = {
  setFilter: (filter: any) => ({type: actionTypes.SET_FILTER, payload: filter}),
}

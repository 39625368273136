import { Moment } from "moment"
import { overrideAdapter } from "../../utils/Utils"

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
export const REPORT_API = `${API_URL}/v1/report`
export type ResponseProfit = {
  category: string[],
  interrest: number[],
  amount: number[],
  created_contract: number,
  average_percent: number,
  paid_total: number,
  payment_total: number,
}
export type ResponseWidgetReport1 = {
  category: string[],
  data: IContractDetails[][],
}

export type ResponseTotalAmount = {
  total: number,
  amount: number,
  interrest: number,
}

export type ResponseIncomeReport = {
  date: string[],
  interrest: number[],
  amount: number[],
  data: number[][]
}

export type ResponseReportReceive = {

}

export function getIncome(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseIncomeReport>(`${REPORT_API}/income?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getProfit(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseProfit>(`${REPORT_API}/profit?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getDue(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseWidgetReport1>(`${REPORT_API}/due?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getOverDue(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseWidgetReport1>(`${REPORT_API}/overdue?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getTotalAmount(options?: any) {
  return overrideAdapter(options?.instead?.token).get<ResponseTotalAmount>(`${REPORT_API}/total/amount`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


export function getNew(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseWidgetReport1>(`${REPORT_API}/new?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getClosed(type: string, options?: any) {
  const params = new URLSearchParams()
  params.append('type', type);
  if (options && options.additionalDate) {
    params.append('year', (options.additionalDate as Moment).format('YYYY'))
    params.append('month', (options.additionalDate as Moment).format('MM'))
  } 
  return overrideAdapter(options?.instead?.token).get<ResponseWidgetReport1>(`${REPORT_API}/closed?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getReceive(from: string, to: string, options: any) {
  const params = new URLSearchParams()
  params.append('from', from);
  params.append('to', to);
  if (options.funder !== undefined) params.append('funder', options.funder);
  if (options.agent !== undefined) params.append('agent', options.agent );
  return overrideAdapter(options?.instead?.token).get<ResponseIncomeReport>(`${REPORT_API}/receive?${params.toString()}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
import axios from "axios";
import moment, { Moment } from "moment-timezone";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";

export function overrideAdapter(token: string | undefined) {
  if (token !== undefined) {
    const instance =  axios.create()
    instance.interceptors.request.use(function (config) {
      console.log(token);
      config.headers.Authorization = `Bearer ${token}`
      return config;
    });
    return instance;
  } else {
    return axios;
  }
}

export function getProfilePhoto(file: string | undefined | null | File) {
  if (file instanceof File) {
    return URL.createObjectURL(file)
  }else if (file) {
    return `${process.env.REACT_APP_API_URL}/profiles/${file}`
  } else {
    return toAbsoluteUrl('/media/avatars/blank.png')
  }
}

export function toMomentLocal(date: string) {
  return moment.tz(date, 'Asia/Bangkok').add(543, 'year');
}

export function dateFormat(date: string) {
  return moment.tz(date, 'Asia/Bangkok').add(543, 'year').format('DD MMM YYYY');
}

export function dateTimeFormat(date: string) {
  return moment.tz(date, 'Asia/Bangkok').add(543, 'year').format('DD MMM YYYY HH:mm:ss');
}

export function getTime(date: string) {
  return moment.tz(date, 'Asia/Bangkok').add(543, 'year').format('HH:mm');
}

export function isDateBetween(currentDate: Moment, startDate: Moment, endDate: Moment) {
  return currentDate.isBetween(startDate, endDate);
}


export function getArrSize(size: number) {
  const arr = []
  for (let i = 0; i < size; i++) {
    arr.push(i);
  }
  return arr;
}

export function isHexColor(str: string) {
  const con = /^#([0-9A-F]{3}){1,2}/
  return con.test(str)
}

export async function getUrlDoc(fileName: string) {
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8001'
  const r = await axios.get(API_URL+`/documents/${fileName}`, {responseType: 'blob'})
  var url = URL.createObjectURL(r.data);
  return url
}

export function getIconContractType(type: number) {
  switch (type) {
    case 1:
      return <>
        <KTSVG
          path='/media/icons/svg/house-chimney-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    case 2:
      return <>
        <KTSVG
          path='/media/icons/svg/car-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    case 3:
      return <>
        <KTSVG
          path='/media/icons/svg/car-slash-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    case 4:
      return <>
        <KTSVG
          path='/media/icons/svg/comments-dollar-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    case 5:
      return <>
        <KTSVG
          path='/media/icons/svg/rotate-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    case 6:
      return <>
        <KTSVG
          path='/media/icons/svg/hand-holding-usd-solid.svg'
          className='svg-icon-1 svg-icon-gray-600'
        />
      </>
    default:
      return <></>
  }
}
import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { ResponseIncomeReport } from '../pages/dashboard/ReportApi';
import commafy from 'commafy';
export interface IncomeReport1Props extends React.HTMLAttributes<HTMLElement> {
  data: ResponseIncomeReport
  chartheight?: string
}
const IncomeReport1: React.FC<IncomeReport1Props> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartRef && !props.data) {
      return;
    }
    const chartState: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          
        }
      }],
      series: [{
        name: 'เงินต้นที่ได้รับ',
        data: props.data.amount
      }, {
        name: 'ดอกเบี้ยที่ได้รับ',
        data: props.data.interrest
      }],
      labels: props.data.date,
      tooltip: {
        
      },
      plotOptions: {
        bar: {
          
        }
      },
      yaxis: {
        title: {
          text: 'จำนวนเงิน (บาท)',
        },
        labels: {
          formatter: (v) => {
            return commafy(v)
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'bottom',
      },
    };
    if (chartState.chart && props.chartheight) {
      chartState.chart.height = props.chartheight
    }
    const chart = new ApexCharts(chartRef.current, chartState);

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [props.data,  props.chartheight])

  return <>
    <div {...props}>
      <div ref={chartRef}></div>    
    </div>
  </>
}

export {IncomeReport1}
import {Action} from '@reduxjs/toolkit'
import { ResponseGetUserList } from '../../pages/user/UserApi'

export interface ActionWithPayload<T> extends Action {
  payload: T
}

export const actionTypes = {
  SET_CONTRACT_FILTER: 'SET_CONTRACT_FILTER',
  SET_CONTRACT_FILTER_DATERANGE: 'SET_CONTRACT_FILTER_DATERANGE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_OFFSET: 'SET_OFFSET',
  SET_FUNDER: "SET_FUNDER",
}

const initialState = {
  filter: {
    paidStatus: [],
    status: [],
    type: [],
    funder: [],
  },
  dateRangeFilter: 'total',
  searchText: '',
  offset: 0,
  funder: undefined,
}

export interface ContractFilter {
  filter: FilterType,
  dateRangeFilter: FilterDateRange,
  searchText: string,
  offset: number,
  funder?: ResponseGetUserList,
}

export interface FilterType {
  paidStatus: Array<string>
  status: Array<string>
  type: Array<string>
  funder: Array<number>
  from?: string
  to?: string
}

export type FilterDateRange = 'week' | 'month' | 'year' | 'total' | string

export const reducer = (state: ContractFilter = initialState, action: ActionWithPayload<any>): ContractFilter => {
  switch (action.type) {
    case actionTypes.SET_CONTRACT_FILTER: {
      state.filter = action.payload
      return state
    }
    case actionTypes.SET_CONTRACT_FILTER_DATERANGE: {
      state.dateRangeFilter = action.payload
      return state
    }
    case actionTypes.SET_SEARCH_TEXT: {
      state.searchText = action.payload
      return state
    }
    case actionTypes.SET_OFFSET: {
      state.offset = action.payload
      return state
    }
    case actionTypes.SET_FUNDER: {
      state.funder = action.payload
      return state
    }
    default:
      return state
  }
}

export const actions = {
  setContractFilter: (filter: FilterType) => ({type: actionTypes.SET_CONTRACT_FILTER, payload: filter}),
  setDateRangeFilter: (filter: FilterDateRange) => ({type: actionTypes.SET_CONTRACT_FILTER_DATERANGE, payload: filter}),
  setSearchText: (filter: string) => ({type: actionTypes.SET_SEARCH_TEXT, payload: filter}),
  setOffset: (filter: number) => ({type: actionTypes.SET_OFFSET, payload: filter}),
  setFunder: (filter: ResponseGetUserList) => ({type: actionTypes.SET_FUNDER, payload: filter}),
}
